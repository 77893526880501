import React from 'react';
import _ from 'lodash';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { DEFAULT_INVENTORY_SETTINGS, INVENTORY_SETTINGS_KEYS } from './inventoryConstants';
import { useUpdateInventorySettings } from '../../../../data-access/mutation/inventorySettings';
import { useInventorySettings } from '../../../../data-access/query/inventorySettings';
import { DATE_RANGE_SELECTION } from '../../../../utils/date-handling';

const areInventorySettingsValid = inventorySettings => {
  return (
    inventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE] > 0 &&
    inventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE] < 100 &&
    inventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE] <=
      inventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE] &&
    inventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE] > 0 &&
    inventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE] < 100 &&
    inventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE] +
      inventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE] <
      100
  );
};

export const useEditInventorySettings = ({ currentLocationId, locations, inventoryEnabled }) => {
  const [inventorySettings, setInventorySettings] = React.useState(() => {
    const initialInventorySettings = {};

    locations.forEach(({ location_id: locationId }) => {
      initialInventorySettings[locationId] = DEFAULT_INVENTORY_SETTINGS;
    });

    return initialInventorySettings;
  });

  const { mutate: updateInventorySettings, isLoading: isUpdatingInventorySettings } = useUpdateInventorySettings();
  const { isLoading: isLoadingInventorySettings } = useInventorySettings({
    onSuccess: data => {
      const currentInventorySettings = [...data];
      let additions = 0;

      locations.forEach(({ location_id: locationId }) => {
        const locationSettings = currentInventorySettings.find(
          ({ location_id: currentId }) => currentId === locationId,
        );

        if (!locationSettings) {
          currentInventorySettings.push({
            location_id: locationId,
            inventory_settings: DEFAULT_INVENTORY_SETTINGS,
          });
          additions += 1;
        }
      });

      if (additions > 0) {
        // Update inventory settings on the backend
        updateInventorySettings(currentInventorySettings);
      }

      const inventorySettingsObject = {};
      currentInventorySettings.forEach(({ location_id: locationId, inventory_settings: settings }) => {
        inventorySettingsObject[locationId] = settings;
      });

      setInventorySettings(inventorySettingsObject);
    },
    enabled: inventoryEnabled,
  });

  const [currentInventorySettings, setCurrentInventorySettings] = React.useState(
    () => inventorySettings[currentLocationId] ?? DEFAULT_INVENTORY_SETTINGS,
  );

  React.useEffect(() => {
    setCurrentInventorySettings(inventorySettings[currentLocationId] ?? DEFAULT_INVENTORY_SETTINGS);
  }, [inventorySettings, currentLocationId]);

  const hasPendingChanges = () => {
    return JSON.stringify(currentInventorySettings) !== JSON.stringify(inventorySettings[currentLocationId]);
  };

  const saveCurrentInventorySettings = () => {
    if (hasPendingChanges() && areInventorySettingsValid(currentInventorySettings)) {
      const updatedInventorySettings = { ...inventorySettings };
      updatedInventorySettings[currentLocationId] = currentInventorySettings;

      const updatedInventorySettingsArray = Object.entries(updatedInventorySettings).map(([locationId, settings]) => ({
        location_id: parseInt(locationId, 10),
        inventory_settings: settings,
      }));

      updateInventorySettings(updatedInventorySettingsArray);
      setInventorySettings(updatedInventorySettings);
    }
  };

  return {
    isLoadingInventorySettings,
    inventorySettings,
    currentInventorySettings,
    setCurrentInventorySettings,
    saveCurrentInventorySettings,
    isUpdatingInventorySettings,
  };
};

const getDataByDateRange = (productCatalog, selectedRange) => {
  const rangeMapping = {
    [DATE_RANGE_SELECTION.NEXT_7_DAYS]: {
      revenue: productCatalog.next7Revenue,
      demand: productCatalog.next7Demand,
      gpp: productCatalog.next7Gpp,
    },
    [DATE_RANGE_SELECTION.NEXT_30_DAYS]: {
      revenue: productCatalog.next30Revenue,
      demand: productCatalog.next30Demand,
      gpp: productCatalog.next30Gpp,
    },
    [DATE_RANGE_SELECTION.NEXT_90_DAYS]: {
      revenue: productCatalog.next90Revenue,
      demand: productCatalog.next90Demand,
      gpp: productCatalog.next90Gpp,
    },
    [DATE_RANGE_SELECTION.NEXT_365_DAYS]: {
      revenue: productCatalog.next365Revenue,
      demand: productCatalog.next365Demand,
      gpp: productCatalog.next365Gpp,
    },
    [DATE_RANGE_SELECTION.CURRENT_MONTH]: {
      revenue: productCatalog.monthRevenue,
      demand: productCatalog.monthDemand,
      gpp: productCatalog.monthGpp,
    },
    [DATE_RANGE_SELECTION.CURRENT_QUARTER]: {
      revenue: productCatalog.quarterRevenue,
      demand: productCatalog.quarterDemand,
      gpp: productCatalog.quarterGpp,
    },
    [DATE_RANGE_SELECTION.CURRENT_YEAR]: {
      revenue: productCatalog.yearRevenue,
      demand: productCatalog.yearDemand,
      gpp: productCatalog.yearGpp,
    },
    [DATE_RANGE_SELECTION.NEXT_MONTH]: {
      revenue: productCatalog.monthNextRevenue,
      demand: productCatalog.monthNextDemand,
      gpp: productCatalog.monthNextGpp,
    },
    [DATE_RANGE_SELECTION.NEXT_QUARTER]: {
      revenue: productCatalog.quarterNextRevenue,
      demand: productCatalog.quarterNextDemand,
      gpp: productCatalog.quarterNextGpp,
    },
    [DATE_RANGE_SELECTION.NEXT_YEAR]: {
      revenue: productCatalog.yearNextRevenue,
      demand: productCatalog.yearNextDemand,
      gpp: productCatalog.yearNextGpp,
    },
  };

  return rangeMapping[selectedRange] || { revenue: 0, demand: 0, gpp: 0 };
};

export const isProductDimColumnName = columnName => columnName.startsWith('productDim');

const productDimensionColumnNameToId = columnName => {
  return columnName.replace('productDim', '').replace('Id', '');
};

export const useProductCatalogDimensions = productCatalog => {
  return React.useMemo(() => {
    return productCatalog.length > 0
      ? Object.keys(productCatalog[0])
          .filter(isProductDimColumnName)
          .map(productDimensionColumnNameToId)
      : [];
  }, [productCatalog]);
};

export const useInventoryProducts = selectedLocationId => {
  const { dimensionValues } = React.useContext(DomainContext);
  const { productCatalog, selectedRange, inventorySettingsApi } = React.useContext(DashboardContext);

  const { currentInventorySettings } = inventorySettingsApi;

  const topProductsPercentage = currentInventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE] / 100;
  const bottomProductsPercentage = currentInventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE] / 100;

  const filteredProductCatalogByLocation = React.useMemo(
    () => productCatalog.filter(p => p.locationId === selectedLocationId),
    [productCatalog, selectedLocationId],
  );

  const productDimCols = useProductCatalogDimensions(productCatalog);

  const inventoryProducts = React.useMemo(() => {
    const productDimValuesById = _.keyBy(dimensionValues, 'id');

    const inventoryProductList = filteredProductCatalogByLocation.map(catalog => {
      const { quantity, price, cost, next365Price, inventoryOutDate, sku, ...rest } = catalog;
      const { revenue, demand, gpp } = getDataByDateRange(rest, selectedRange);

      // TODO: This value should come from the user input
      const orderQuantityPlan = 0;

      const currentPrice = next365Price > 0 ? next365Price : price > 0 ? price : 0;
      const missedGrossProfitPotential = Math.max((demand - quantity - orderQuantityPlan) * (currentPrice - cost), 0);
      const currentMissedGrossProfitPotential = quantity <= 0 ? gpp : missedGrossProfitPotential;

      const dimensionColumns = Object.entries(rest).reduce((acc, [columnName, value]) => {
        if (isProductDimColumnName(columnName)) {
          acc[productDimensionColumnNameToId(columnName)] = productDimValuesById[value]?.value;
        }
        return acc;
      }, {});

      return {
        sku,
        inventoryOutDate,
        cost,
        price: currentPrice,
        grossProfitPotential: gpp,
        revenuePotential: revenue,
        salesQuantityPotential: demand,
        currentStock: quantity,
        orderQuantityRec: Math.max(demand - quantity, 0),
        orderQuantityPlan,
        missedGrossProfitPotential: currentMissedGrossProfitPotential,
        missedRevenue: Math.max(revenue - Math.max(quantity - orderQuantityPlan, 0) * currentPrice, 0),
        missedSalesQuantity: Math.max(demand - Math.max(quantity - orderQuantityPlan, 0), 0),
        ...dimensionColumns,
      };
    });

    return inventoryProductList.sort((a, b) => b.grossProfitPotential - a.grossProfitPotential);
  }, [dimensionValues, filteredProductCatalogByLocation, selectedRange]);

  const { segmentAProducts, segmentBProducts, segmentCProducts } = React.useMemo(() => {
    // total GPP
    const totalGpp = inventoryProducts.reduce((sum, product) => sum + product.grossProfitPotential, 0);
    const segmentAThreshold = totalGpp * topProductsPercentage; // Accumulated GPP for segment A
    const segmentCThreshold = totalGpp * bottomProductsPercentage; // Accumulated GPP for segment C

    // Calculate the indices for the segments
    let accumulatedGpp = 0;
    let segmentAEndIndex = 0;
    let segmentCStartIndex = inventoryProducts.length;

    for (let i = 0; i < inventoryProducts.length; i++) {
      accumulatedGpp += inventoryProducts[i].grossProfitPotential;

      if (accumulatedGpp >= segmentAThreshold && segmentAEndIndex === 0) {
        segmentAEndIndex = i + 1; // End of segment A
      }

      if (accumulatedGpp >= totalGpp - segmentCThreshold && segmentCStartIndex === inventoryProducts.length) {
        segmentCStartIndex = i; // Start of segment C
        break;
      }
    }

    // Create the segments
    const segmentA = inventoryProducts.slice(0, segmentAEndIndex);
    const segmentB = inventoryProducts.slice(segmentAEndIndex, segmentCStartIndex);
    const segmentC = inventoryProducts.slice(segmentCStartIndex);

    return { segmentAProducts: segmentA, segmentBProducts: segmentB, segmentCProducts: segmentC };
  }, [inventoryProducts, topProductsPercentage, bottomProductsPercentage]);

  return { inventoryProducts, productDimCols, segmentAProducts, segmentBProducts, segmentCProducts };
};

export const prepareInventoryDownload = (segment, products, dimensions, timePeriod) => {
  // Create a mapping of dimension IDs to their labels
  const dimensionMap = dimensions.reduce((map, dimension) => {
    map[dimension.id] = dimension.label;
    return map;
  }, {});

  // Transform each product
  return products.map(product => {
    const transformedProduct = {};

    // Add dimension values first
    Object.entries(product).forEach(([key, value]) => {
      if (dimensionMap[key]) {
        transformedProduct[dimensionMap[key]] = value;
      }
    });

    if (product.sku) {
      // This value is not always present
      transformedProduct.SKU = product.sku;
    }

    // Add remaining product properties
    transformedProduct.Segment = segment;
    transformedProduct[`${timePeriod} Sales Qty Potential`] = product.salesQuantityPotential;
    transformedProduct['Current Stock'] = product.currentStock;
    transformedProduct['RoverRecs Order Qty'] = product.orderQuantityRec;
    transformedProduct['Inventory Out Date'] = product.inventoryOutDate;

    return transformedProduct;
  });
};
