import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import HourEditor from './HourEditor';
import { DashboardContext } from './revenueUpliftContexts';
import { LABOR_PERCENT_METRIC, MAX_GRAPH_LABOR_PCT, TOTAL_HEADCOUNT_METRIC } from './staffingTabConstants';
import StaffingTabContext from './staffingTabContext';
import {
  calculateShiftDuration,
  addHalfHoursToRange,
  formatNumber,
  getLastShiftHour,
  getShiftEndTime,
  isHalfHour,
} from './staffingTabUtil';
import { formatAsDatasetDate } from './timePeriod';
import { ALL_HOURS_AND_HALVES } from '../../../accountSettings/locationSettings/locationManagementConstants';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { convertTo12HourFormat, formatPercentage } from '../../../util/format';
import { useGridApi } from '../../../util/gridCommonFunctions';
import {
  gaEmitAddShiftButtonClick,
  gaEmitCopyToPlannerButtonClick,
  gaEmitDeleteShiftClick,
} from '../../../../google-analytics/staffingTab';
import { FONT_BLACK } from '../../../../colors';
import './tables.scss';

const borderClass = 'table-border-right';
const hourColumnWidth = 68;

const defaultColDef = {
  sortable: false,
  resizable: false,
  headerClass: borderClass,
  cellClass: borderClass,
  minWidth: hourColumnWidth,
  suppressMovable: true,
};

const TableLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-self-center">
      <CenteredProfitRoverSpinner />
    </div>
  );
};

const valueFormatter = (currencySymbol, selectedMetric) => {
  return params => {
    const { value } = params;
    const number = value ?? 0;

    switch (selectedMetric) {
      case TOTAL_HEADCOUNT_METRIC:
        return formatNumber(number);
      case LABOR_PERCENT_METRIC:
        if (number > MAX_GRAPH_LABOR_PCT) {
          return `>${formatPercentage(MAX_GRAPH_LABOR_PCT)}`;
        }
        return formatPercentage(number);
      default:
        return formatNumber(number, { currencySymbol });
    }
  };
};

const hourFormatter = params => {
  const {
    value,
    context: { is24HourFormat },
  } = params;

  return is24HourFormat ? value : convertTo12HourFormat(value);
};

const hourRenderer = props => {
  const { data = {}, value, context = {} } = props;
  const { start, end } = data;
  const { hoursRange = [] } = context;

  const extendedRange = addHalfHoursToRange(hoursRange);

  const startIdx = extendedRange.indexOf(start);
  const endIdx = extendedRange.indexOf(end);
  const valueIdx = extendedRange.indexOf(value);

  const isActive = valueIdx >= startIdx && valueIdx <= endIdx;
  const isStart = valueIdx === startIdx;
  const isEnd = valueIdx === endIdx;

  const valueHour = value.split(':')[0];
  const startHour = start.split(':')[0];
  const endHour = end.split(':')[0];
  const isHalfStartHour = start.includes(':30') && valueHour === startHour;
  const isHalfEndHour = end.includes(':30') && valueHour === endHour;

  return (
    <div
      className={classnames('hour-cell', {
        'hour-cell-active': isActive,
        'hour-cell-start': isStart,
        'hour-cell-end': isEnd,
        'hour-cell-half-start': isHalfStartHour,
        'hour-cell-half-end': isHalfEndHour,
      })}
    />
  );
};

const editableHourRenderer = props => {
  const {
    value,
    context: { is24HourFormat },
  } = props;

  return (
    <div className="d-flex align-items-center" style={{ gap: '0.25rem' }}>
      <div>{is24HourFormat ? value : convertTo12HourFormat(value)}</div>
      <FontAwesomeIcon icon={faPencilAlt} color={FONT_BLACK} size="sm" />
    </div>
  );
};

const shiftDurationGetter = params => {
  const { data = {} } = params;
  const { start, end } = data;

  return calculateShiftDuration(start, end);
};

const hourEndGetter = params => {
  const { data = {}, context = {} } = params;
  const { end } = data;
  const { hoursRange = [] } = context;

  return getShiftEndTime(end, hoursRange);
};

const frameworkComponents = {
  hourRenderer,
  editableHourRenderer,
  HourEditor,
};

const shiftNumberWidth = 60;
const startTimeWidth = 75;
const endTimeWidth = 75;
const durationWidth = 100;

export const HourBreakdownTable = ({ selectedMetric, selectedMetricLabel, plansData, isActive }) => {
  const { currencySymbol, is24HourFormat } = React.useContext(DashboardContext);
  const { extendedHourRange, selectedDate } = React.useContext(StaffingTabContext);

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const newColumnDefs = extendedHourRange.map(hour => ({
        headerName: is24HourFormat ? hour : convertTo12HourFormat(hour),
        field: hour,
        headerClass: `${borderClass} center-text table-header`,
        cellClass: `${borderClass} center-text`,
        valueFormatter: valueFormatter(currencySymbol, selectedMetric),
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs(newColumnDefs);
    }
  }, [extendedHourRange, currencySymbol, selectedMetric, gridApi, selectedDate, is24HourFormat]);

  return (
    <div
      className="table-container mt-4"
      ref={containerRef}
      style={{ width: hourColumnWidth * extendedHourRange.length }}
    >
      <div className="metric-container">
        <div className="metric">{selectedMetricLabel}</div>
        {plansData.map(plan => (
          <div className="plan" key={plan.label}>
            {plan.label}
          </div>
        ))}
      </div>
      {isLoading && <TableLoader />}
      <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
        <AgGridReact
          rowData={plansData}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          groupHeaderHeight={24}
          headerHeight={24}
          rowHeight={24}
          singleClickEdit
          suppressModelUpdateAfterUpdateTransaction
          stopEditingWhenCellsLoseFocus
          rowBuffer={70}
          suppressHorizontalScroll
        />
      </div>
    </div>
  );
};

export const RecommendedTable = ({ isActive }) => {
  const { extendedHourRange, dayShifts, resetDayShifts, selectedDate } = React.useContext(StaffingTabContext);
  const { staffingPlannerApi, locationsApi, is24HourFormat } = React.useContext(DashboardContext);
  const { selectedLocation } = locationsApi;
  const { value: locationId } = selectedLocation;
  const { staffingPlanner = {} } = staffingPlannerApi;

  const staffingPlannerShifts = staffingPlanner[locationId]?.[formatAsDatasetDate(selectedDate)] ?? [];
  const staffingPlannerDefined = staffingPlanner[locationId]?.[formatAsDatasetDate(selectedDate)] != null;
  const hasSavedShifts = staffingPlannerShifts.length > 0 || staffingPlannerDefined;

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const hourColumnDefs = extendedHourRange.map(hour => ({
        headerName: is24HourFormat ? hour : convertTo12HourFormat(hour),
        headerClass: `${borderClass} center-text table-header`,
        cellClass: 'hour-cell-container',
        cellRenderer: 'hourRenderer',
        valueGetter: () => hour,
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs([
        {
          headerName: 'Shift #',
          field: 'shift',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          width: shiftNumberWidth,
          minWidth: shiftNumberWidth,
          maxWidth: shiftNumberWidth,
        },
        {
          colId: 'startTime',
          headerName: 'Start Time',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          field: 'start',
          valueFormatter: hourFormatter,
          width: startTimeWidth,
          minWidth: startTimeWidth,
          maxWidth: startTimeWidth,
        },
        {
          colId: 'endTime',
          headerName: 'End Time',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          field: 'end',
          valueGetter: hourEndGetter,
          valueFormatter: hourFormatter,
          width: endTimeWidth,
          minWidth: endTimeWidth,
          maxWidth: endTimeWidth,
        },
        {
          headerName: 'Duration (Hrs)',
          field: 'duration',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          valueGetter: shiftDurationGetter,
          width: durationWidth,
          minWidth: durationWidth,
          maxWidth: durationWidth,
        },
        ...hourColumnDefs,
      ]);
    }
  }, [extendedHourRange, gridApi, selectedDate, is24HourFormat]);

  const onCopyToPlanner = () => {
    gaEmitCopyToPlannerButtonClick();
    resetDayShifts();
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center mt-3 mb-2">
        <h2 className="subtitle mr-2">RoverRecs</h2>
        {!isLoading && (
          <ProfitRoverTooltip
            shouldDisplayTooltip={!hasSavedShifts}
            tooltipText="Staffing Planner currently set to RoverRecs"
            placement="right"
            delay={{ show: 100, hide: 100 }}
          >
            <ProfitRoverSecondaryButton small onClick={onCopyToPlanner} disabled={!hasSavedShifts}>
              Copy to Planner
            </ProfitRoverSecondaryButton>
          </ProfitRoverTooltip>
        )}
      </div>
      <div
        className="table-container mt-1"
        ref={containerRef}
        style={{
          minWidth:
            hourColumnWidth * extendedHourRange.length +
            shiftNumberWidth +
            startTimeWidth +
            endTimeWidth +
            durationWidth,
        }}
      >
        {isLoading && <TableLoader />}
        <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
          <AgGridReact
            rowData={dayShifts}
            onGridReady={onGridReady}
            context={{ hoursRange: extendedHourRange, is24HourFormat }}
            frameworkComponents={frameworkComponents}
            defaultColDef={defaultColDef}
            groupHeaderHeight={24}
            headerHeight={24}
            rowHeight={24}
            singleClickEdit
            suppressModelUpdateAfterUpdateTransaction
            stopEditingWhenCellsLoseFocus
            rowBuffer={70}
            suppressHorizontalScroll
          />
        </div>
      </div>
    </>
  );
};

const hourValueSetter = ({ newValue, data, colDef, context }) => {
  const { field } = colDef;
  const { shift } = data;
  const { hoursRange = [], dayShiftPlans, setDayShiftPlans } = context;

  if (!ALL_HOURS_AND_HALVES.includes(newValue)) {
    // Not a valid hour
    return false;
  }

  const actualEndHour =
    field === 'end' ? (isHalfHour(newValue) ? newValue : getLastShiftHour(newValue, hoursRange)) : data.end;
  const actualStartHour = field === 'start' ? newValue : data.start;

  setDayShiftPlans(
    dayShiftPlans.map(currentShift => {
      if (currentShift.shift === shift) {
        return {
          ...currentShift,
          start: actualStartHour,
          end: actualEndHour,
        };
      }
      return currentShift;
    }),
    shift,
  );

  return true;
};

export const ShiftPlannerTable = ({ isActive }) => {
  const { is24HourFormat } = React.useContext(DashboardContext);
  const {
    extendedHourRange,
    setDayShiftPlans,
    dayShiftPlans,
    removeDayShift,
    addDayShift,
    selectedDate,
  } = React.useContext(StaffingTabContext);

  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  const isLoading = gridApi == null;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi && containerRef.current) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  });

  React.useEffect(() => {
    if (gridApi) {
      const hourColumnDefs = extendedHourRange.map(hour => ({
        headerName: is24HourFormat ? hour : convertTo12HourFormat(hour),
        headerClass: `${borderClass} center-text table-header`,
        cellClass: 'hour-cell-container',
        cellRenderer: 'hourRenderer',
        valueGetter: () => hour,
        colId: `${formatAsDatasetDate(selectedDate)}-${hour}`,
      }));

      gridApi.setColumnDefs([
        {
          headerName: 'Shift #',
          field: 'shift',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          width: shiftNumberWidth,
          minWidth: shiftNumberWidth,
          maxWidth: shiftNumberWidth,
        },
        {
          colId: 'startTime',
          headerName: 'Start Time',
          editable: true,
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text editable`,
          field: 'start',
          cellEditor: 'HourEditor',
          cellEditorParams: { type: 'start' },
          valueSetter: hourValueSetter,
          cellRenderer: 'editableHourRenderer',
          width: startTimeWidth,
          minWidth: startTimeWidth,
          maxWidth: startTimeWidth,
        },
        {
          colId: 'endTime',
          headerName: 'End Time',
          editable: true,
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text editable`,
          field: 'end',
          valueGetter: hourEndGetter,
          cellEditor: 'HourEditor',
          cellEditorParams: { type: 'end' },
          valueSetter: hourValueSetter,
          cellRenderer: 'editableHourRenderer',
          width: endTimeWidth,
          minWidth: endTimeWidth,
          maxWidth: endTimeWidth,
        },
        {
          headerName: 'Duration (Hrs)',
          field: 'duration',
          headerClass: `${borderClass} center-text table-header`,
          cellClass: `${borderClass} center-text`,
          valueGetter: shiftDurationGetter,
          width: durationWidth,
          minWidth: durationWidth,
          maxWidth: durationWidth,
        },
        ...hourColumnDefs,
      ]);
    }
  }, [extendedHourRange, gridApi, selectedDate, is24HourFormat]);

  const onAddDayShift = () => {
    gaEmitAddShiftButtonClick();
    addDayShift();
  };

  const onRemoveDayShift = shift => {
    gaEmitDeleteShiftClick();
    removeDayShift(shift);
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center mb-2">
        <h2 className="subtitle mr-2">Staffing Planner</h2>
        {!isLoading && (
          <ProfitRoverSecondaryButton small onClick={onAddDayShift}>
            Add Shift
          </ProfitRoverSecondaryButton>
        )}
      </div>
      <div
        className="table-container mt-1"
        ref={containerRef}
        style={{
          minWidth:
            hourColumnWidth * extendedHourRange.length +
            shiftNumberWidth +
            startTimeWidth +
            endTimeWidth +
            durationWidth,
        }}
      >
        {isLoading && <TableLoader />}
        <div className={classnames('ag-theme-alpine table', { hidden: isLoading })}>
          {!isLoading && (
            <div className="remove-shifts-container">
              {dayShiftPlans.map(({ shift }) => (
                <button key={shift} type="button" className="delete-button" onClick={() => onRemoveDayShift(shift)}>
                  <FontAwesomeIcon icon={faTrash} color={FONT_BLACK} size="sm" />
                </button>
              ))}
            </div>
          )}
          <AgGridReact
            rowData={dayShiftPlans}
            onGridReady={onGridReady}
            frameworkComponents={frameworkComponents}
            defaultColDef={defaultColDef}
            context={{ hoursRange: extendedHourRange, dayShiftPlans, setDayShiftPlans, is24HourFormat }}
            groupHeaderHeight={24}
            headerHeight={24}
            rowHeight={24}
            singleClickEdit
            suppressModelUpdateAfterUpdateTransaction
            stopEditingWhenCellsLoseFocus
            rowBuffer={70}
            suppressHorizontalScroll
          />
        </div>
      </div>
    </>
  );
};
