/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faFilter,
  faSquare,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { orderBy, partition, groupBy } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { Form, useForm, useField } from 'react-final-form';
import { useHistory } from 'react-router';
import { useClickAway } from 'react-use';
import { getOrderByCriteria } from './filtersUtil';
import {
  BASE_RECOMMENDATIONS,
  BASE_RECOMMENDATIONS_LABELS,
  HOURS_OF_OPERATION_SETTINGS_KEYS,
} from './hoursOfOperationConstants';
import { INVENTORY_SETTINGS_KEYS } from './inventoryConstants';
import { useProductCatalogDimensions } from './inventoryTabUtil';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { TABS } from './revenueUpliftDashboardTabs';
import {
  MAX_SHIFT_LENGTH,
  MIN_HOURLY_REVENUE_PER_EMPLOYEE,
  MIN_HOURLY_STAFF,
  MIN_SHIFT_LENGTH,
  STAFFING_RECOMMENDATIONS,
  STAFFING_RECOMMENDATIONS_LABELS,
  STAFFING_ROLE_KEYS,
} from './staffingTabConstants';
import { POPOVER_ID } from './TimePeriodPicker';
import { TextInput } from '../../../forms/GenericFormComponents';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { convertTo12HourFormat } from '../../../util/format';
import { LIGHT_GREEN, FONT_BLACK, MID_GREEN } from '../../../../colors';
import { gaEmitDimensionFilterAppliedClick } from '../../../../google-analytics/revenueUpliftDashboardV2';
import { gaEmitStaffingSettingClick } from '../../../../google-analytics/staffingTab';
import {
  gaEmitCurrentHoursOfOperationLinkClick,
  gaEmitMinimumRevenueThresholdInputChange,
} from '../../../../google-analytics/hoursTab';
import './filters-menu.scss';

const FILTER_CONTAINER_COLLAPSE_BUTTON_ID = 'filter-container-collapse-button-id';
const DimensionName = ({ label, disabled }) => (
  <label className={classnames('eo-price-dim-preview-name', { disabled })}>{label}</label>
);

const FilterDimension = props => {
  const {
    dimensionPreview,
    onResetFilters,
    onClickDimensionToFocus,
    showFilteringIndicator,
    disabled,
    activeTabLabel,
  } = props;

  const { label, dimValues } = dimensionPreview;
  const expandable = dimValues.length > 1;

  const ToggleExpandedButton = () => (
    <button
      type="button"
      aria-label="expand-filters"
      tabIndex={!expandable ? '-1' : '0'}
      className={classnames('eo-price-toggle-expansion-button', { hidden: !expandable })}
      onClick={expandable ? onClickDimensionToFocus : undefined}
      style={{ marginRight: 5 }}
      disabled={disabled}
    >
      {expandable && <FontAwesomeIcon icon={faChevronRight} size="sm" />}
    </button>
  );

  const FilteringIndicatorButton = ({ hidden }) => (
    <button
      aria-label="remove-filters"
      type="submit"
      onClick={onResetFilters}
      className={classnames('eo-price-filtering-indicator-btn', { hidden })}
    >
      <FilterCancelIcon style={{ width: '100%', height: '100%' }} />
    </button>
  );

  return (
    <div className="eo-price-dim-label-and-filter-actions-row">
      <ProfitRoverTooltip
        tooltipText={`Not applicable to ${activeTabLabel}`}
        shouldDisplayTooltip={disabled}
        placement="bottom-start"
        delay={200}
      >
        <div className="eo-price-dim-label">
          <ToggleExpandedButton />
          <DimensionName label={label} disabled={disabled} />
        </div>
      </ProfitRoverTooltip>
      <FilteringIndicatorButton hidden={!showFilteringIndicator} />
    </div>
  );
};

const SingleDimValueRow = props => {
  const { label, onClick, isSelected, isDisabled } = props;

  const onClickBar = !isDisabled ? onClick : undefined;

  return (
    <div
      role="button"
      tabIndex={isDisabled ? '-1' : '0'}
      className={classnames('single-dim-value-row', { selected: isSelected, disabled: isDisabled })}
      onClick={onClickBar}
      onKeyPress={e => onClickBar && e.key === 'Enter' && onClickBar()}
    >
      <ProfitRoverCheckBoxBase checked={isSelected} onClick={onClickBar} tabIndex="-1" />
      <div className="dim-value-name">{label}</div>
    </div>
  );
};

const BackButton = ({ onClick }) => {
  return (
    <button className="eo-price-back-button" type="button" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} size="sm" />
    </button>
  );
};

// TODO: Move this to GenericComponents.jsx (and update CSS)
const ProfitRoverCheckBoxBase = ({ square, checked, onClick, style, tabIndex }) => {
  let checkboxContents;

  if (square) {
    checkboxContents = <FontAwesomeIcon icon={faSquare} color="#676767" style={{ fontSize: 12 }} />;
  } else if (checked) {
    checkboxContents = <FontAwesomeIcon icon={faCheck} style={{ fontSize: 10 }} />;
  }

  return (
    <button
      type="button"
      tabIndex={tabIndex}
      className="profitrover-selection-checkbox"
      onClick={onClick}
      style={style}
    >
      {checkboxContents}
    </button>
  );
};

const SelectAllCheckBox = props => {
  const { allValuesSelected, noValuesSelected, dimensionId } = props;
  const { selectAllValues, unselectAllValues } = React.useContext(DashboardContext);

  const showBlackSquare = !allValuesSelected && !noValuesSelected;

  let clickHandler;
  let square = false;
  let checked = false;
  if (showBlackSquare) {
    // A subset of values are selected
    clickHandler = () => selectAllValues(dimensionId);
    square = true;
  } else if (allValuesSelected) {
    // Every value is selected
    clickHandler = () => unselectAllValues(dimensionId);
    checked = true;
  } else if (noValuesSelected) {
    // No values are selected
    clickHandler = () => selectAllValues(dimensionId);
  }

  return (
    <div className="select-all-text">
      <ProfitRoverCheckBoxBase
        square={square}
        checked={checked}
        onClick={clickHandler}
        style={{ marginLeft: 9, marginRight: 11.5 }}
      />
      <button type="button" style={{ fontSize: 16, padding: 0, outline: 'none' }} onClick={clickHandler}>
        Select All
      </button>
    </div>
  );
};

const SelectAllSearchResultsTextbox = props => {
  const { allValuesSelected, noValuesSelected, dimensionId, matchingIds, unMatchingIds } = props;
  const { selectDimensionValues, unselectDimensionValues } = React.useContext(DashboardContext);

  const showBlackSquare = !allValuesSelected && !noValuesSelected;

  let clickHandler;
  let square = false;
  let checked = false;

  if (showBlackSquare) {
    // A subset of values are selected
    clickHandler = () => selectDimensionValues(dimensionId, unMatchingIds);
    square = true;
  } else if (allValuesSelected) {
    // Every value is selected
    clickHandler = () => unselectDimensionValues(dimensionId, matchingIds);
    checked = true;
  } else if (noValuesSelected) {
    // No values are selected
    clickHandler = () => selectDimensionValues(dimensionId, unMatchingIds);
  }

  return (
    <div className="select-all-text">
      <ProfitRoverCheckBoxBase
        square={square}
        checked={checked}
        onClick={clickHandler}
        style={{ marginLeft: 9, marginRight: 11.5 }}
      />
      <button
        type="button"
        style={{ fontSize: 16, padding: 0, outline: 'none', width: 'max-content' }}
        onClick={clickHandler}
      >
        Select All Search Results
      </button>
    </div>
  );
};

const useTextFilterState = () => {
  const [textValue, setTextValue] = React.useState('');

  const resetTextValue = React.useCallback(() => {
    setTextValue('');
  }, []);

  const updateTextValue = React.useCallback(event => {
    setTextValue(event.target.value);
  }, []);

  return { textValue, updateTextValue, resetTextValue };
};

const FocusedDimensionViewer = () => {
  const { is24HourFormat } = React.useContext(DomainContext);
  const {
    dimensionPreviews,
    focusedDimensionId,
    clearFocusedDimension,
    visuallySelectedDatasetDimValuesSets,
    selectionState,
    selectDimensionValue,
    unselectDimensionValue,
  } = React.useContext(DashboardContext);

  const { textValue, updateTextValue, resetTextValue } = useTextFilterState();
  const isTextSearching = textValue !== '';

  const focusedDimensionPreview = dimensionPreviews.find(({ id }) => id === focusedDimensionId);

  const { id: dimensionId, label: dimensionLabel, dimValues } = focusedDimensionPreview;

  const orderByCriteria = getOrderByCriteria(dimensionId);
  const sortedDimValues = orderBy(dimValues, orderByCriteria, ['asc']);

  const numberOfDimValues = selectionState[dimensionId].length;
  const selectedValuesSet = visuallySelectedDatasetDimValuesSets[dimensionId];

  const noValuesSelected = selectedValuesSet.size === 0;
  const allValuesSelected = selectedValuesSet.size === numberOfDimValues;

  const mapDimValToRow = (dimVal, disabledRows) => {
    const { isSelected, label } = dimVal;

    let rowLabel = label;

    if (dimensionLabel === 'Hour' && !is24HourFormat) {
      rowLabel = convertTo12HourFormat(label);
    }

    const onClick = () => {
      if (isSelected) {
        unselectDimensionValue(dimensionId, label);
      } else {
        selectDimensionValue(dimensionId, label);
        gaEmitDimensionFilterAppliedClick(dimensionLabel);
      }
    };

    return (
      <SingleDimValueRow
        key={label}
        label={rowLabel}
        isSelected={isSelected}
        isDisabled={disabledRows}
        onClick={onClick}
      />
    );
  };

  let dimValueRows;
  let checkBox;
  if (isTextSearching) {
    // Show "filtered" representation of the rows, separated by a <hr>
    const [matchingValues, nonMatchingValues] = partition(sortedDimValues, row => {
      // Case insensitive search matching
      const dimValueLabel = row.label.toLocaleLowerCase();
      const searchText = textValue.toLocaleLowerCase();

      const rowMatchesSearchText = dimValueLabel.search(searchText) !== -1;
      return rowMatchesSearchText;
    });

    let [visuallySelectedMatchingIds, visuallyUnselectedMatchingIds] = partition(matchingValues, upliftStats =>
      selectedValuesSet.has(upliftStats.dimValueId),
    );

    // TODO: Make this work with dimensionValueIds in the useReducer instead of string labels for faster comparisons
    const toLabel = upliftStats => upliftStats.label;
    visuallySelectedMatchingIds = visuallySelectedMatchingIds.map(toLabel);
    visuallyUnselectedMatchingIds = visuallyUnselectedMatchingIds.map(toLabel);

    const noMatchingValuesSelected = visuallySelectedMatchingIds.length === 0;
    const allMatchingValuesSelected = visuallySelectedMatchingIds.length === matchingValues.length;

    checkBox = (
      <SelectAllSearchResultsTextbox
        allValuesSelected={allMatchingValuesSelected}
        noValuesSelected={noMatchingValuesSelected}
        dimensionId={dimensionId}
        matchingIds={visuallySelectedMatchingIds}
        unMatchingIds={visuallyUnselectedMatchingIds}
      />
    );
    dimValueRows = (
      <>
        {matchingValues.map(dimVal => mapDimValToRow(dimVal, false))}
        {matchingValues.length > 0 && <hr />}
        {nonMatchingValues.map(dimVal => mapDimValToRow(dimVal, true))}
      </>
    );
  } else {
    // Show all rows because the filter text is empty
    checkBox = (
      <SelectAllCheckBox
        allValuesSelected={allValuesSelected}
        noValuesSelected={noValuesSelected}
        dimensionId={dimensionId}
      />
    );
    dimValueRows = sortedDimValues.map(dimVal => mapDimValToRow(dimVal, false));
  }

  return (
    <>
      <div className="dim-viewer-top-bar">
        <BackButton onClick={clearFocusedDimension} />
        <DimensionName label={dimensionLabel} />
      </div>

      <div>
        <TextInput
          className="dim-viewer-filter-search-input"
          value={textValue}
          onChange={updateTextValue}
          placeholder="Filter Search"
          icon={
            isTextSearching ? (
              <span
                tabIndex="0"
                role="button"
                onClick={resetTextValue}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    resetTextValue();
                  }
                }}
                style={{
                  display: 'inline-flex',
                  marginLeft: 7,
                  marginRight: 6,
                  marginTop: 1,
                  height: 15,
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: 14 }} />
              </span>
            ) : (
              <FontAwesomeIcon
                icon={faFilter}
                flip="horizontal"
                style={{ marginLeft: 7.5, marginRight: 8, marginTop: 1, fontSize: 14 }}
              />
            )
          }
        />
      </div>

      <div className="dim-values-header-row">
        <span className="dim-select-all">{checkBox}</span>
      </div>

      <hr className="dim-value-separator" />

      <div className="dim-value-uplift-bars-container">{dimValueRows}</div>
    </>
  );
};

const DimensionsOverview = ({ activeTabId, activeTabLabel }) => {
  const {
    dimensionPreviews,
    filteredDimensionsSet,
    productCatalog,
    unselectAllValues,
    setFocusedDimensionId,
  } = React.useContext(DashboardContext);

  const productCatalogDimensions = useProductCatalogDimensions(productCatalog).map(dimId => Number(dimId));

  // Sort the dimensions according to the value specified by the user
  const focusableDimPreviews = dimensionPreviews.filter(dimPreview => dimPreview.dimValues.length > 1);
  let groupedDimensionPreviews = groupBy(focusableDimPreviews, 'category');

  if (activeTabId === TABS.INVENTORY) {
    // Only show the dimensions that are present in the product catalog
    groupedDimensionPreviews = {
      ...groupedDimensionPreviews,
      Product: groupedDimensionPreviews.Product.filter(item => productCatalogDimensions.includes(item.id)),
    };
  }

  return Object.entries(groupedDimensionPreviews).map(([category, categoryDimensionPreviews]) => {
    return (
      <div key={category} className="price-dim-category">
        <h6 className="price-dim-category-label">{category}</h6>
        {categoryDimensionPreviews.map(dimensionPreview => {
          const { id: dimensionId, applicableTabs = [] } = dimensionPreview;

          const hasUnselectedDimValues = filteredDimensionsSet.has(dimensionId.toString()); // filteredDimensionsSets only contain strings

          const handleFocusDimension = () => {
            setFocusedDimensionId(dimensionId);
          };

          return (
            <FilterDimension
              key={dimensionId}
              dimensionPreview={dimensionPreview}
              showFilteringIndicator={hasUnselectedDimValues}
              onClickDimensionToFocus={handleFocusDimension}
              onResetFilters={() => unselectAllValues(dimensionId)}
              disabled={applicableTabs.length > 0 && !applicableTabs.includes(activeTabId)}
              activeTabLabel={activeTabLabel}
            />
          );
        })}
      </div>
    );
  });
};

const FilterCancelIcon = ({ style }) => (
  <span className="fa-layers fa-fw" style={{ position: 'relative', ...style }}>
    <FontAwesomeIcon icon={faFilter} style={{ fontSize: 14 }} color={FONT_BLACK} />
    <FontAwesomeIcon
      icon={faTimesCircle}
      style={{ fontSize: 8, position: 'relative', left: 5, top: -3, background: 'black', borderRadius: '100%' }}
      color={LIGHT_GREEN}
    />
  </span>
);

const ResetAllFiltersButton = ({ onClick }) => {
  return (
    <button className={classnames('reset-all-filters-button')} type="button" onClick={onClick}>
      <FilterCancelIcon style={{ marginRight: 3, right: 3, top: 1.5 }} />
      Reset All
    </button>
  );
};

const FilterControlsCollapseButton = ({ show, onClick }) => {
  const [collapseButtonChevronColor, setCollapseButtonChevronColor] = React.useState('black');
  const [collapseButtonFillColor, setCollapseButtonFillColor] = React.useState('white');

  const toggleCollapseControlHighlight = () => {
    if (collapseButtonChevronColor === 'black') {
      setCollapseButtonChevronColor('white');
    } else {
      setCollapseButtonChevronColor('black');
    }

    if (collapseButtonFillColor === 'white') {
      setCollapseButtonFillColor('rgb(73, 170, 224)');
    } else {
      setCollapseButtonFillColor('white');
    }
  };

  return (
    <div className="eo-price-fcst-filter-controls-collapse-outer-container" hidden={!show}>
      <div style={{ flexGrow: 1 }} />
      <div className="eo-price-fcst-filter-controls-collapse-inner-container">
        <ProfitRoverTooltip
          shouldDisplayTooltip
          tooltipId="collapse-button"
          tooltipClass="eo-price-fcst-filter-controls-collapse-button-tooltip"
          placement="left"
          tooltipText="Collapse"
          onToggle={toggleCollapseControlHighlight}
        >
          <button
            id={FILTER_CONTAINER_COLLAPSE_BUTTON_ID}
            type="button"
            className="eo-price-fcst-filter-controls-collapse-button"
            onClick={onClick}
          >
            <svg className="eo-price-fcst-filter-controls-collapse-circle">
              <circle cx="12" cy="12" r="10" fill={collapseButtonFillColor} stroke="#00a9e0" strokeWidth="2" />
            </svg>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="eo-price-fcst-filter-controls-collapse-chevron"
              style={{ color: collapseButtonChevronColor }}
              transform="right-1"
            />
          </button>
        </ProfitRoverTooltip>
      </div>
    </div>
  );
};

const DarkBackdrop = () => <div className="dark-backdrop" />;

export const SettingsMenuContainer = ({ isOpen, onClose, children }) => {
  const [showCollapseButton, setShowCollapseButton] = React.useState(false);
  const overlayRef = React.useRef(null);

  useClickAway(overlayRef, e => {
    const container = document.getElementById(POPOVER_ID);
    const ignoreClick = container && container.contains(e.target);

    if (isOpen && !ignoreClick) {
      onClose();
    }
  });

  return (
    <>
      {isOpen && <DarkBackdrop />}
      <div
        data-testid={isOpen ? 'filters-menu-open' : 'filters-menu-closed'}
        ref={overlayRef}
        className={classnames('filters-menu eo-price-fcst-left-container', { open: isOpen })}
        onMouseMove={e => {
          if (isOpen) {
            const containerElement = overlayRef.current;
            if (containerElement != null) {
              const { width, left } = containerElement.getBoundingClientRect();
              const xCoordinateInsideContainer = e.clientX - left;

              const isNearRightEdge = xCoordinateInsideContainer > width * 0.95;
              if (isNearRightEdge && !showCollapseButton) {
                setShowCollapseButton(true);
              } else if (!isNearRightEdge && showCollapseButton) {
                setShowCollapseButton(false);
              }
            }
          }
        }}
        onMouseLeave={() => setShowCollapseButton(false)}
        style={
          showCollapseButton ? { borderRight: '2px solid rgba(73, 170, 224, 1)' } : { borderRight: '2px solid white' }
        }
      >
        <FilterControlsCollapseButton show={showCollapseButton} onClick={onClose} />
        {children}
      </div>
    </>
  );
};

const FiltersMenu = ({ isOpen, setIsOpen, activeTabId, activeTabLabel }) => {
  const { focusedDimensionId, resetSelectedState } = React.useContext(DashboardContext);
  const isFocused = focusedDimensionId != null;

  const closeFiltersMenu = () => setIsOpen(false);

  return (
    <SettingsMenuContainer isOpen={isOpen} onClose={closeFiltersMenu}>
      <div className="top-part pt-3">
        <div className="heading">
          <h5>Filters</h5>
          <ResetAllFiltersButton onClick={resetSelectedState} />
        </div>
        <hr />
      </div>
      <div className={classnames('eo-price-dim-filter-menu', { 'dimension-distributions': !focusedDimensionId })}>
        {!isFocused ? (
          <DimensionsOverview activeTabId={activeTabId} activeTabLabel={activeTabLabel} />
        ) : (
          <FocusedDimensionViewer />
        )}
      </div>
    </SettingsMenuContainer>
  );
};

const required = value => (value != null && value !== '' ? undefined : 'Required');
const mustBeNumber = value => (Number.isNaN(value) ? 'Must be a number' : undefined);
const minValue = min => value =>
  Number.isNaN(value) || value >= min ? undefined : `Must be greater or equal to ${min}`;
const maxValue = max => value => (Number.isNaN(value) || value <= max ? undefined : `Must be less or equal to ${max}`);
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const SettingsInputField = ({ name, min, max, setValue, validate, prefix, suffix }) => {
  const timeoutRef = React.useRef(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { input, meta } = useField(name, { validate });

  const onChange = event => {
    input.onChange(event);

    const {
      target: { value },
    } = event;
    const error = validate != null ? validate(value) : null;
    if (!error) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Input debounce
      timeoutRef.current = setTimeout(() => {
        setValue(value);
      }, 800);

      setErrorMessage(null);
    } else {
      setErrorMessage(error);
    }
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={classnames('input-wrapper', { error: errorMessage != null && meta.dirty })}>
        {prefix && <span className="prefix">{prefix}</span>}
        <input
          {...input}
          className="item-input"
          type="number"
          min={min}
          max={max}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        {suffix && <span className="suffix">{suffix}</span>}
      </div>
      {errorMessage != null && meta.dirty && <span className="error-message">{errorMessage}</span>}
    </>
  );
};

const StaffingSettingsInputField = ({
  name,
  label,
  min,
  max,
  validate,
  prefix,
  suffix,
  impactsRecommendations = false,
}) => {
  const { staffingRolesApi } = React.useContext(DashboardContext);
  const { currentStaffingRole, setCurrentStaffingRole } = staffingRolesApi;
  const onSetValue = value => {
    gaEmitStaffingSettingClick(name);
    setCurrentStaffingRole({ ...currentStaffingRole, [name]: parseInt(value, 10) });
  };

  return (
    <div className="item">
      {label && (
        <p className="item-label">
          {label} <span style={{ color: impactsRecommendations ? LIGHT_GREEN : MID_GREEN }}>*</span>
        </p>
      )}
      <SettingsInputField
        name={name}
        min={min}
        max={max}
        prefix={prefix}
        suffix={suffix}
        validate={validate}
        setValue={onSetValue}
      />
    </div>
  );
};

const StaffingForm = () => {
  const { staffingRolesApi, hasForecastStats, currencySymbol } = React.useContext(DashboardContext);
  const { currentStaffingRole, setCurrentStaffingRole } = staffingRolesApi;

  const form = useForm();

  React.useEffect(() => {
    form.initialize(currentStaffingRole);
  }, [currentStaffingRole, form]);

  const handleRecommendationsOption = event => {
    const { value } = event.target;
    setCurrentStaffingRole({
      ...currentStaffingRole,
      [STAFFING_ROLE_KEYS.BASE_RECOMMENDATIONS_ON]: value,
    });
  };

  return (
    <form className="flex-form">
      <div className="role-form">
        <h6 className="category-label">Min Hourly Staff</h6>
        <div className="category">
          <StaffingSettingsInputField
            label="Day"
            name={STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY}
            min={MIN_HOURLY_STAFF}
            validate={composeValidators(required, mustBeNumber, minValue(MIN_HOURLY_STAFF))}
            impactsRecommendations
          />
          <StaffingSettingsInputField
            label="Close"
            name={STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE}
            min={MIN_HOURLY_STAFF}
            validate={composeValidators(required, mustBeNumber, minValue(MIN_HOURLY_STAFF))}
            impactsRecommendations
          />
        </div>
        <h6 className="category-label">Shift Length</h6>
        <div className="category">
          <StaffingSettingsInputField
            label="Min"
            name={STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH}
            suffix="Hrs"
            min={MIN_SHIFT_LENGTH}
            max={currentStaffingRole[STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH]}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(MIN_SHIFT_LENGTH),
              maxValue(currentStaffingRole[STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH]),
            )}
            impactsRecommendations
          />
          <StaffingSettingsInputField
            label="Max"
            name={STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH}
            suffix="Hrs"
            min={currentStaffingRole[STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]}
            max={MAX_SHIFT_LENGTH}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(currentStaffingRole[STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]),
              maxValue(MAX_SHIFT_LENGTH),
            )}
            impactsRecommendations
          />
        </div>

        <h6 className="category-label">Staffing Recommendation Approach</h6>
        <span>Base my staffing recommendations on:</span>
        <div className="radio-button-container">
          {Object.values(STAFFING_RECOMMENDATIONS).map(recommendation => (
            <label key={recommendation}>
              <input
                className="item"
                type="radio"
                value={recommendation}
                checked={currentStaffingRole[STAFFING_ROLE_KEYS.BASE_RECOMMENDATIONS_ON] === recommendation}
                onChange={handleRecommendationsOption}
              />
              {STAFFING_RECOMMENDATIONS_LABELS[recommendation]}
            </label>
          ))}
        </div>

        <h6 className="category-label">
          Average Hourly Wages<span style={{ color: LIGHT_GREEN }}>*</span>
        </h6>
        <div className="category">
          <StaffingSettingsInputField
            name={STAFFING_ROLE_KEYS.AVERAGE_HOURLY_WAGES}
            prefix={currencySymbol}
            min={0}
            validate={composeValidators(required, mustBeNumber, minValue(0))}
          />
        </div>
        <h6 className="category-label">Labor Cost (% of Revenue)</h6>
        <div className="category">
          <StaffingSettingsInputField
            label="Target Min"
            name={STAFFING_ROLE_KEYS.MIN_LABOR_COST_PCT}
            suffix="%"
            min={0}
            max={currentStaffingRole[STAFFING_ROLE_KEYS.MAX_LABOR_COST_PCT]}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(0),
              maxValue(currentStaffingRole[STAFFING_ROLE_KEYS.MAX_LABOR_COST_PCT]),
            )}
            impactsRecommendations
          />
          <StaffingSettingsInputField
            label="Target Max"
            name={STAFFING_ROLE_KEYS.MAX_LABOR_COST_PCT}
            suffix="%"
            min={currentStaffingRole[STAFFING_ROLE_KEYS.MIN_LABOR_COST_PCT]}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(currentStaffingRole[STAFFING_ROLE_KEYS.MIN_LABOR_COST_PCT]),
            )}
          />
        </div>

        <h6 className="category-label">Revenue Per Employee Hour</h6>
        <div className="category">
          <StaffingSettingsInputField
            label="Target Min"
            name={STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE}
            prefix={currencySymbol}
            min={0}
            max={currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE]}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(0),
              maxValue(currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE]),
            )}
          />
          <StaffingSettingsInputField
            label="Target Max"
            name={STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE}
            prefix={currencySymbol}
            min={Math.max(
              currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE],
              MIN_HOURLY_REVENUE_PER_EMPLOYEE,
            )}
            validate={composeValidators(
              required,
              mustBeNumber,
              minValue(
                Math.max(
                  currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE],
                  MIN_HOURLY_REVENUE_PER_EMPLOYEE,
                ),
              ),
            )}
            impactsRecommendations
          />
        </div>
        {hasForecastStats && (
          <>
            <h6 className="category-label">Tips Per Employee Hour</h6>
            <div className="category">
              <StaffingSettingsInputField
                label="Target Min"
                name={STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE}
                prefix={currencySymbol}
                min={0}
                max={currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE]}
                validate={composeValidators(
                  required,
                  mustBeNumber,
                  minValue(0),
                  maxValue(currentStaffingRole[STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE]),
                )}
              />
              <StaffingSettingsInputField
                label="Target Max"
                name={STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE}
                prefix={currencySymbol}
                min={currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE]}
                validate={composeValidators(
                  required,
                  mustBeNumber,
                  minValue(currentStaffingRole[STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE]),
                )}
              />
            </div>
          </>
        )}
        <div className="legend">
          <div className="label">
            <span style={{ color: LIGHT_GREEN }}>*</span> Impacts Shift Recommendations
          </div>
          <div className="label">
            <span style={{ color: MID_GREEN }}>*</span> Used for Graphs Only
          </div>
        </div>
      </div>
    </form>
  );
};

export const StaffingSettings = ({ isOpen, setIsOpen }) => {
  const { staffingRolesApi, locationsApi } = React.useContext(DashboardContext);
  const { saveCurrentStaffingRole } = staffingRolesApi;
  const { selectedLocation } = locationsApi;

  const closeSettings = () => {
    saveCurrentStaffingRole();
    setIsOpen(false);
  };

  return (
    <SettingsMenuContainer isOpen={isOpen} onClose={closeSettings}>
      <div className="top-part pt-3">
        <div className="heading">
          <h5>Staffing Settings for {selectedLocation?.label}</h5>
        </div>
        <hr />
      </div>
      <Form onSubmit={closeSettings} render={() => <StaffingForm />} />
    </SettingsMenuContainer>
  );
};

const HoursForm = () => {
  const { hoursOfOperationSettingsApi, currencySymbol } = React.useContext(DashboardContext);
  const { currentHoursSettings, setCurrentHoursSettings } = hoursOfOperationSettingsApi;

  const form = useForm();
  const history = useHistory();

  React.useEffect(() => {
    form.initialize(currentHoursSettings);
  }, [currentHoursSettings, form]);

  const goToLocationSettings = event => {
    event.preventDefault();

    gaEmitCurrentHoursOfOperationLinkClick();
    history.push('/location-settings');
  };

  const handleRecommendationsOption = event => {
    const { value } = event.target;
    setCurrentHoursSettings({
      ...currentHoursSettings,
      [HOURS_OF_OPERATION_SETTINGS_KEYS.BASE_RECOMMENDATIONS_ON]: value,
    });
  };

  // const variationValue = {
  //   value: currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.VARY_HOURS_OF_OPERATION_BY],
  //   label:
  //     HOURS_OF_OPERATION_VARIATION_LABELS[
  //       currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.VARY_HOURS_OF_OPERATION_BY]
  //     ],
  // };

  return (
    <form className="mt-3">
      <div className="role-form">
        {/* Our recommendations logic does not yet support hours of operation structure */}
        {/* <h6 className="category-label">Hours of Operation Structure</h6>
        <div className="category full-width">
          <div className="item">
            <p className="description">Vary my hours of operation recommendations by:</p>
            <div className="input-container">
              <RevenueUpliftSelect
                value={variationValue}
                options={HOURS_OF_OPERATION_VARIATION_OPTIONS}
                onChange={option =>
                  setCurrentHoursSettings({
                    ...currentHoursSettings,
                    [HOURS_OF_OPERATION_SETTINGS_KEYS.VARY_HOURS_OF_OPERATION_BY]: option.value,
                  })
                }
                placeholder="Select Variation"
              />
            </div>
          </div>
        </div> */}
        <h6 className="category-label">Minimum Revenue Threshold</h6>
        <div className="category full-width">
          <div className="item">
            <p className="description">Recommend I close if my hourly revenue is projected to be below:</p>
            <div className="input-container">
              <SettingsInputField
                name={HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD}
                prefix={currencySymbol}
                min={1}
                validate={composeValidators(required, mustBeNumber, minValue(1))}
                setValue={value => {
                  gaEmitMinimumRevenueThresholdInputChange();
                  setCurrentHoursSettings({
                    ...currentHoursSettings,
                    [HOURS_OF_OPERATION_SETTINGS_KEYS.HOURLY_REVENUE_CLOSE_THRESHOLD]: parseInt(value, 10),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <h6 className="category-label">Base my RoverRecs on:</h6>
        <div className="radio-button-container">
          {Object.values(BASE_RECOMMENDATIONS).map(recommendation => (
            <label key={recommendation}>
              <input
                className="item"
                type="radio"
                value={recommendation}
                checked={
                  currentHoursSettings[HOURS_OF_OPERATION_SETTINGS_KEYS.BASE_RECOMMENDATIONS_ON] === recommendation
                }
                onChange={handleRecommendationsOption}
              />
              {BASE_RECOMMENDATIONS_LABELS[recommendation]}
            </label>
          ))}
        </div>

        <h6 className="category-label">Current Hours of Operation</h6>
        <div className="category full-width">
          <p className="description">
            Go{' '}
            <a href="#!" onClick={goToLocationSettings}>
              here
            </a>{' '}
            to ensure your current hours of operation are accurate
          </p>
        </div>
      </div>
    </form>
  );
};

export const HoursSettings = ({ isOpen, setIsOpen }) => {
  const { hoursOfOperationSettingsApi, locationsApi } = React.useContext(DashboardContext);
  const { saveCurrentHoursSettings } = hoursOfOperationSettingsApi;
  const { selectedLocation } = locationsApi;

  const closeSettings = () => {
    saveCurrentHoursSettings();
    setIsOpen(false);
  };

  return (
    <SettingsMenuContainer isOpen={isOpen} onClose={closeSettings}>
      <div className="top-part pt-3">
        <div className="heading">
          <h5>Hours of Operation Settings for {selectedLocation?.label}</h5>
        </div>
        <hr />
      </div>
      <Form onSubmit={closeSettings} render={() => <HoursForm />} />
    </SettingsMenuContainer>
  );
};

const InventorySettingsInputField = ({ name, label, min, max, validate, prefix, suffix }) => {
  const { inventorySettingsApi } = React.useContext(DashboardContext);
  const { currentInventorySettings, setCurrentInventorySettings } = inventorySettingsApi;

  const onSetValue = value => {
    setCurrentInventorySettings({
      ...currentInventorySettings,
      [name]: parseInt(value, 10),
    });
  };

  return (
    <div className="item">
      <p className="item-label">{label}</p>
      <SettingsInputField
        name={name}
        min={min}
        max={max}
        validate={validate}
        prefix={prefix}
        suffix={suffix}
        setValue={onSetValue}
      />
    </div>
  );
};

const InventoryForm = () => {
  const { inventorySettingsApi } = React.useContext(DashboardContext);
  const { currentInventorySettings } = inventorySettingsApi;

  const form = useForm();

  React.useEffect(() => {
    form.initialize(currentInventorySettings);
  }, [currentInventorySettings, form]);

  return (
    <form className="mt-3">
      <div className="role-form">
        <h6 className="category-label">Product Segmentation (% of Gross Profit Potential)</h6>
        <div className="category">
          <InventorySettingsInputField
            label="A (Top)"
            name={INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE}
            min={1}
            max={100}
            suffix="%"
            validate={composeValidators(required, mustBeNumber, minValue(1), maxValue(100))}
          />
          <InventorySettingsInputField
            label="C (Bottom)"
            name={INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE}
            min={1}
            max={100}
            suffix="%"
            validate={composeValidators(required, mustBeNumber, minValue(1), maxValue(100))}
          />
        </div>
      </div>
    </form>
  );
};

export const InventorySettings = ({ isOpen, setIsOpen }) => {
  const { inventorySettingsApi, locationsApi } = React.useContext(DashboardContext);
  const { saveCurrentInventorySettings } = inventorySettingsApi;
  const { selectedLocation } = locationsApi;

  const closeSettings = () => {
    saveCurrentInventorySettings();
    setIsOpen(false);
  };

  return (
    <SettingsMenuContainer isOpen={isOpen} onClose={closeSettings}>
      <div className="top-part pt-3">
        <div className="heading">
          <h5>Inventory Settings for {selectedLocation?.label}</h5>
        </div>
        <hr />
      </div>
      <Form onSubmit={closeSettings} render={() => <InventoryForm />} />
    </SettingsMenuContainer>
  );
};

export default FiltersMenu;
