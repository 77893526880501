import React from 'react';
import { useHistory } from 'react-router';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import { useTopHoursOfOperationRecs } from './hoursTabUtil';
import { NEXT_90_PERIOD } from './periodConstants';
import useLocationName from './hooks/useLocationName';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { convertTo12HourFormat } from '../../../util/format';
import { useGridApi } from '../../../util/gridCommonFunctions';
import { gaEmitTopHoursHereLinkClick, gaEmitTopHoursSeeMoreLinkClick } from '../../../../google-analytics/atAGlanceTab';

const ProfitRoverIcon = '/images/ProfitRoverLogo.png';
const TOP_RESULTS_COUNT = 3;
const COLUMN_MIN_WIDTH = 150;

const buildHeaderTemplate = icon => {
  return `
      <div class="ag-cell-label-container" role="presentation">
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <img class="price-rec-table-header-icon" src="${icon}" />
          <span ref="eText" role="columnheader"></span>
        </div>
      </div>`;
};

const upliftTotalRenderer = props => {
  if (props.revenueHourUplift == null || !Number.isFinite(props.revenueHourUplift)) {
    return '-';
  }

  const isPositive = props.revenueHourUplift >= 0;
  const isZero = props.revenueHourUplift === 0;

  return (
    <span className="price-rec-gain-total">
      <div
        className={!isZero ? classNames('graph-total-triangle', isPositive ? 'triangle-up ' : 'triangle-down') : null}
      />
      {!isZero ? props.value : '- - -'}
    </span>
  );
};

const formatHours = params => {
  const {
    value,
    context: { is24HourFormat },
  } = params;

  // Handle empty or undefined values
  if (!value) {
    return '';
  }

  // Split the time range, e.g., "11:00 - 18:00"
  const [start, end] = value.split(' - ');

  // Return "-" if start or end is empty (location is closed)
  if (!start || !end) {
    return '-';
  }

  if (is24HourFormat) {
    return value;
  }

  const start12 = convertTo12HourFormat(start);
  const end12 = convertTo12HourFormat(end);

  return `${start12} - ${end12}`;
};

const TopHoursOfOperationRecsTable = ({ isActive }) => {
  const { is24HourFormat, workflowId } = React.useContext(DomainContext);
  const { isLoadingHours, locationsApi, currencySymbol } = React.useContext(DashboardContext);
  const { locationOptions } = locationsApi;
  const { getLocationName } = useLocationName();
  const topResults = useTopHoursOfOperationRecs(locationOptions, NEXT_90_PERIOD, TOP_RESULTS_COUNT, currencySymbol);
  const containerRef = React.useRef();
  const { gridApi, onGridReady } = useGridApi();

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, topResults, isActive]);

  const isLoading = gridApi == null || isLoadingHours;

  return (
    <div className="eo-price-rec-table-container" ref={containerRef}>
      {isLoading && (
        <div className="w-100 d-flex justify-content-center align-self-center">
          <CenteredProfitRoverSpinner />
        </div>
      )}
      <div className={classNames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
        <AgGridReact
          rowData={topResults}
          context={{ workflowId, is24HourFormat }}
          onGridReady={onGridReady}
          defaultColDef={{
            resizable: true,
          }}
          frameworkComponents={{
            upliftTotalRenderer,
          }}
          groupHeaderHeight={30}
          headerHeight={30}
          maintainColumnOrder
        >
          <AgGridColumn
            field="location"
            headerName="Location"
            headerClass="border-right center-text"
            cellClass="border-right"
            valueGetter={params => getLocationName(params.data.location)}
            minWidth={COLUMN_MIN_WIDTH}
          />
          <AgGridColumn
            field="dayOfWeek"
            headerName="Day of Week"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            minWidth={COLUMN_MIN_WIDTH}
          />
          <AgGridColumn
            field="currentHours"
            headerName="Current Hours"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            valueFormatter={formatHours}
            minWidth={COLUMN_MIN_WIDTH}
          />
          <AgGridColumn
            field="recommendedHours"
            headerName="RoverRecs"
            headerComponentParams={{
              template: buildHeaderTemplate(ProfitRoverIcon),
            }}
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            valueFormatter={formatHours}
            minWidth={COLUMN_MIN_WIDTH}
          />
          <AgGridColumn
            field="formattedRevenueHourUplift"
            headerName="90-Day Revenue/Hr Uplift"
            headerClass="border-right center-text"
            cellClass="border-right center-text"
            cellRenderer="upliftTotalRenderer"
            cellRendererParams={params => ({ revenueHourUplift: params.data.revenueHourUplift })}
            minWidth={COLUMN_MIN_WIDTH}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

const TopHoursOfOperationRecsSection = ({ showHoursTab, isActive }) => {
  const history = useHistory();

  const onClickSeeMore = () => {
    gaEmitTopHoursSeeMoreLinkClick();
    showHoursTab();
  };

  const goToLocationSettings = event => {
    gaEmitTopHoursHereLinkClick();
    event.preventDefault();
    history.push('/location-settings');
  };

  return (
    <div className="top-recs-section w-100 pb-2">
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="d-flex mt-3 mb-2">
          <h5 className="mb-0">Top Hours of Operation Recommendations</h5>
          <ProfitRoverTooltip
            tooltipText="Follow these recommendations to optimize your revenue per open hour over the next 90 days"
            placement="top"
            delay={{ show: 100, hide: 100 }}
          >
            <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
          </ProfitRoverTooltip>
          <button type="button" className="link-like ml-2" onClick={onClickSeeMore}>
            See More
          </button>
        </div>
        <ProfitRoverCard className="table-card p-3" style={{ flex: 1 }}>
          <TopHoursOfOperationRecsTable isActive={isActive} />
          <div className="d-flex mt-2">
            <p>
              Ensure your current hours are accurate and up-to-date by setting them{' '}
              <a href="#!" onClick={goToLocationSettings}>
                here
              </a>
              .
            </p>
          </div>
        </ProfitRoverCard>
      </div>
    </div>
  );
};

export default TopHoursOfOperationRecsSection;
