import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingPage from './common/LoadingPage';
import CommunicationPreferences from './CommunicationPreferences';
import SettingsNavigationMenu from './SettingsNavigationMenu';
import SettingsPageWrapper from './SettingsPageWrapper';
import NAVIGATION_SEPARATOR from './settingsNavigationMenuConstants';
import DisplayPreferences from './DisplayPreferences';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import Header from '../header/header';
import { isEoPriceoptWorkflow } from '../workflow/workflowFunctions';
import { useWorkflows } from '../../data-access/query/workflows';
import { usePreferences, USER_PREFERENCES_SUBJECT } from '../../data-access/query/preferences';

const Preferences = () => {
  const { data: workflows = [], isLoading: isLoadingWorkflows } = useWorkflows();
  const kpWorkflows = workflows.filter(isEoPriceoptWorkflow);

  const userId = React.useRef(localStorage.getItem('id')).current;
  const { data: emailPreferences = {}, isLoading: isLoadingEmailPreferences } = usePreferences(
    userId,
    USER_PREFERENCES_SUBJECT.EMAIL,
  );
  const { data: displayPreferences = {}, isLoading: isLoadingDisplayPreferences } = usePreferences(
    userId,
    USER_PREFERENCES_SUBJECT.DISPLAY,
  );

  const isLoading = isLoadingWorkflows || isLoadingEmailPreferences || isLoadingDisplayPreferences;

  // This page should only be accessible if the user has one KP workflow
  if (!isLoadingWorkflows && kpWorkflows.length !== 1) {
    return <Redirect to="/personalSettings" />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label={`Settings ${NAVIGATION_SEPARATOR} Preferences`} tabsId={['settings']} />
      <SettingsPageWrapper>
        <div className="container">
          <h1 className="settings-header">Preferences</h1>
          <div className="container" style={{ gap: '1rem' }}>
            <CommunicationPreferences data={emailPreferences} />
            <DisplayPreferences data={displayPreferences} />
          </div>
        </div>
      </SettingsPageWrapper>
    </HeaderAwarePage>
  );
};

export default Preferences;
