import _ from 'lodash';
import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import { Tab, Tabs } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import DatasetSampleTable from './DatasetSampleTable';
import { dataSourceSupportsDedicatedFileUpload } from './DataSourceInstructions';
import {
  buildUserDimFields,
  buildItemQuantityField,
  buildLocationField,
  buildPriceField,
  buildTransactionDateField,
  ConfigFieldName,
  buildHourDimField,
} from './fieldBuilders';
import ManageDatasetsTable from './ManageDatasetsTable';
import { ConfigTableContext, useConfigTableContext } from './settingsAccordion/configTableContext';
import ItemDescriptorAccordionItem from './settingsAccordion/ItemDescriptorAccordion';
import ItemQuantityAccordionItem from './settingsAccordion/ItemQuantityAccordion';
import LocationAccordion from './settingsAccordion/LocationAccordion';
import PriceAccordionItem from './settingsAccordion/PriceAccordion';
import TransactionDateAccordionItem from './settingsAccordion/TransactionDateAccordion';
import SettingsNavigationMenu from '../accountSettings/SettingsNavigationMenu';
import { DataSourceFields, DataType } from '../data-center/datasetConfig/DatasetConfigFormConstants';
import DatasetFieldBuilder from '../data-center/datasetConfig/DatasetFieldBuilder';
import { PageHeader } from '../forms/GenericFormComponents';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import RetryOrGoToFallback from '../generic/errorBoundary/RetryOrGoToFallback';
import GenericLoadingPage from '../generic/GenericLoadingPage';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import LinkWithDirectionalArrow from '../generic/LinkWithDirectionalArrow';
import Header from '../header/header';
import { useNavigateToDataCenter } from '../notificationCenter/notificationCenterHooks';
import { useUpdateGuidedSetupState } from '../setup/modern/guidedSetupHooks';
import PreviousStep from '../setup/modern/PreviousStep';
import { ProgressBar, TrackedSteps } from '../setup/modern/ProgressBar';
import ProfitRoverSpinner from '../spinner/ProfitRoverSpinner';
import { HOUR_DIMENSION_TYPE, Objective, WorkflowDescription } from '../workflow/workflowConstants';
import {
  useUpdateDatasetConfig,
  useAddFieldsToDatasetConfig,
  useUpdateConfigFields,
  useDeleteConfigFields,
} from '../../data-access/mutation/datasetConfigs';
import { useDeleteLocation } from '../../data-access/mutation/locations';
import { useCreateWorkflow } from '../../data-access/mutation/workflows';
import { useDatasetConfig } from '../../data-access/query/datasetConfigs';
import { useDatasetSample } from '../../data-access/query/datasets';
import { useDimensions, useDimensionTypes } from '../../data-access/query/dimensions';
import { useGuidedSetup } from '../../data-access/query/guidedSetup';
import { useLocations } from '../../data-access/query/locations';
import './data-source-settings.scss';

const Heading = ({ datasetConfigName }) => (
  <PageHeader className="data-source-settings-heading">{datasetConfigName}</PageHeader>
);

class UnableToLoadSamplesError extends Error {
  constructor() {
    super("Something's gone wrong. Please re-upload your dataset.");

    this.title = 'Unable to Load Dataset Sample';
    this.primaryButtonText = 'Try Again';
    this.secondaryButtonText = 'Go Home';
  }
}

const INSTRUCTION_LINE_1 = `Please go through each required field below to tell us how to properly read your data. We
are providing a sample of your dataset as well so you can easily see your columns.`;
const INSTRUCTION_LINE_2 = `You can tell us which columns in your dataset match up with the following types of
information.`;

const SectionHeader = ({ children }) => <h5 className="section-header">{children}</h5>;

const ExpandAll = ({ allFieldIds }) => {
  const contextValue = useConfigTableContext();
  const { setPreExpanded, setExpandedAccordion, setBulkExpandCollapseToggle, bulkExpandCollapseToggle } = contextValue;

  const expandAll = () => {
    setPreExpanded(allFieldIds);
    setExpandedAccordion(allFieldIds);
    setBulkExpandCollapseToggle(!bulkExpandCollapseToggle);
  };

  return (
    <div className="config-table-bulk-action" role="button" tabIndex={0} onClick={expandAll} onKeyPress={expandAll}>
      Expand All
    </div>
  );
};

const CollapseAll = () => {
  const {
    setExpandedAccordion,
    setPreExpanded,
    setBulkExpandCollapseToggle,
    bulkExpandCollapseToggle,
  } = useConfigTableContext();

  const collapseAll = () => {
    setExpandedAccordion([]);
    setPreExpanded([]);
    setBulkExpandCollapseToggle(!bulkExpandCollapseToggle);
  };

  return (
    <div className="config-table-bulk-action" role="button" tabIndex={0} onClick={collapseAll} onKeyPress={collapseAll}>
      Collapse All
    </div>
  );
};

// convert these user-friendly field names to valid HTML5 id values by removing spaces
Object.keys(DataSourceFields).forEach(field => {
  DataSourceFields[field] = field.replace(' ', '');
});

const FIELD_TO_ACCORDION_ITEM = {
  LOCATION: 'location-accordion',
  TRANSACTION_DATE: 'transaction-date-accordion',
  ITEM_QUANTITY: 'item-quantity-accordion',
  PRICE: 'price-accordion',
  ITEM_DESCRIPTORS: 'item-descriptor-accordion',
};

const TranFields = () => (
  <>
    <LocationAccordion name={FIELD_TO_ACCORDION_ITEM.LOCATION} uuid={DataSourceFields.LOCATION} />
    <TransactionDateAccordionItem
      name={FIELD_TO_ACCORDION_ITEM.TRANSACTION_DATE}
      uuid={DataSourceFields.TRANSACTION_DATE}
    />
    <ItemQuantityAccordionItem name={FIELD_TO_ACCORDION_ITEM.ITEM_QUANTITY} uuid={DataSourceFields.ITEM_QUANTITY} />
    <PriceAccordionItem name={FIELD_TO_ACCORDION_ITEM.PRICE} uuid={DataSourceFields.PRICE} />
    <ItemDescriptorAccordionItem
      name={FIELD_TO_ACCORDION_ITEM.ITEM_DESCRIPTORS}
      uuid={DataSourceFields.ITEM_DESCRIPTORS}
    />
  </>
);

const AdvTranFields = () => (
  <>
    <LocationAccordion name={FIELD_TO_ACCORDION_ITEM.LOCATION} uuid={DataSourceFields.LOCATION} />
    <TransactionDateAccordionItem
      name={FIELD_TO_ACCORDION_ITEM.TRANSACTION_DATE}
      uuid={DataSourceFields.TRANSACTION_DATE}
    />

    {/* TODO Add 'Event Date' accordion */}

    <ItemQuantityAccordionItem name={FIELD_TO_ACCORDION_ITEM.ITEM_QUANTITY} uuid={DataSourceFields.ITEM_QUANTITY} />
    <PriceAccordionItem name={FIELD_TO_ACCORDION_ITEM.PRICE} uuid={DataSourceFields.PRICE} />
    <ItemDescriptorAccordionItem
      name={FIELD_TO_ACCORDION_ITEM.ITEM_DESCRIPTORS}
      uuid={DataSourceFields.ITEM_DESCRIPTORS}
    />
  </>
);

const DATA_TYPE_TO_FIELDS = {
  [DataType.TRAN]: TranFields,
  [DataType.ADVTRAN]: AdvTranFields,
};

const ConfigTableContextAPI = ({
  children,
  datasetColumnSamples,
  naicsCode,
  kaCode,
  dataType,
  industry,
  datasetConfigFields,
}) => {
  const { data: dimensions = [] } = useDimensions(true);
  const { data: locations = [] } = useLocations();

  const [preExpanded, setPreExpanded] = React.useState([]);
  const [expandedAccordion, setExpandedAccordion] = React.useState([]);
  /*
   * This boolean value does not actually express if all accordion items are expanded or collapsed, rather it is used as
   * a key on the accordion component which, when the value changes, forces it to re-initialize. See also the
   * collapseAll and expandAll methods.
   */
  const [bulkExpandCollapseToggle, setBulkExpandCollapseToggle] = React.useState(false);

  const columnNames = datasetColumnSamples.map(columnSample => columnSample?.header_name);
  columnNames.sort();
  const columnNameOptions = columnNames.map(colName => ({ label: colName, value: colName }));

  return (
    <ConfigTableContext.Provider
      value={{
        expandedAccordion,
        setExpandedAccordion,
        bulkExpandCollapseToggle,
        setBulkExpandCollapseToggle,
        preExpanded,
        setPreExpanded,
        columnNames,
        columnNameOptions,
        naicsCode,
        kaCode,
        dataType,
        industry,
        dimensions,
        locations,
        datasetConfigFields: datasetConfigFields ?? [],
      }}
    >
      {children}
    </ConfigTableContext.Provider>
  );
};

const DataSourceSettingsWrapper = props => (
  <ErrorBoundary
    fallbackRender={renderProps => (
      <>
        <Header />
        <RetryOrGoToFallback {...renderProps} />
      </>
    )}
  >
    <DataSourceSettings {...props} />
  </ErrorBoundary>
);

const ConfigurationTable = ({ onSubmit, Fields, fieldIds }) => {
  const { preExpanded, expandedAccordion, setExpandedAccordion, bulkExpandCollapseToggle } = useConfigTableContext();

  const allFieldIds = fieldIds;
  const anyExpanded = expandedAccordion.length !== 0;

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        addNewLocation: (args, state, tools) => {
          const [name, newLocationOption] = args[0];
          tools.changeValue(state, name, () => newLocationOption);
        },
        ...arrayMutators,
      }}
      render={props => {
        const { handleSubmit, submitting, invalid, dirty } = props;

        return (
          <form className="config-table-form" onSubmit={handleSubmit}>
            <div className="config-table-heading">
              <SectionHeader>Settings</SectionHeader>
              {anyExpanded ? <CollapseAll /> : <ExpandAll allFieldIds={allFieldIds} />}
            </div>
            <div className="config-table-accordion-container">
              <Accordion
                className={classnames('config-table-accordion', expandedAccordion ? 'selected' : 'unselected')}
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={preExpanded}
                onChange={uuid => setExpandedAccordion(uuid)}
                key={bulkExpandCollapseToggle}
              >
                <Fields />
              </Accordion>
            </div>

            {/* 
              Developer Note: The "dirty" boolean breaks after submitting changes due to what appears
              to be a bug in react-final-form. Should we ever need to rely on this functionality again, a
              GitHub issue has been filed here: https://github.com/final-form/react-final-form/issues/988.

              Other possible "workarounds":
              - Convert price-accordion.priceType to a Boolean-like field to emulate "radio button" behavior
              - Use a "lastSaved" timestamp as the "key" prop on the <Form> to get the form to reinitialize
                after each save
            */}

            <ProfitRoverPrimaryButton
              className="confirm-settings-button"
              type="submit"
              disabled={invalid || submitting || !dirty}
              style={{ minWidth: 169 }}
            >
              {!submitting ? 'Confirm Settings' : <ProfitRoverSpinner />}
            </ProfitRoverPrimaryButton>
          </form>
        );
      }}
    />
  );
};

/**
 * "Simple" means fields that can be build without needing to make any additional network requests
 * (unlike, for example, DIM type fields that will need to create (or lookup) a product dimension
 * that the dataset config field will reference)
 */
const constructSimpleFields = (fieldIds, formState) => {
  const simpleFields = fieldIds
    .map(fieldId => {
      const accordionItemKey = FIELD_TO_ACCORDION_ITEM[fieldId];
      const formStateForField = formState[accordionItemKey];

      switch (fieldId) {
        case DataSourceFields.LOCATION: {
          return buildLocationField(formStateForField);
        }
        case DataSourceFields.TRANSACTION_DATE: {
          return buildTransactionDateField(formStateForField);
        }
        case DataSourceFields.ITEM_QUANTITY: {
          return buildItemQuantityField(formStateForField);
        }
        case DataSourceFields.PRICE: {
          return buildPriceField(formStateForField);
        }
        default:
          // Ignore any "complex" fields (to be handled by code executed after this)
          return null;
      }
    })
    .filter(field => field != null);

  return simpleFields;
};

const constructUserDimFieldChanges = async (formState, existingDimFields, dimsById) => {
  const associatedDimIds = [];

  const key = FIELD_TO_ACCORDION_ITEM[DataSourceFields.ITEM_DESCRIPTORS];
  const itemDescriptorsFormState = formState[key];

  const userDimFields = await buildUserDimFields(itemDescriptorsFormState);

  userDimFields.forEach(dimField => associatedDimIds.push(dimField.product_dimension_id));

  const [dimFieldsWithIds, dimFieldsWithoutIds] = _.partition(
    userDimFields,
    dimField => dimField.dataset_field_id != null,
  );

  const existingUserDimFields = existingDimFields.filter(dimField => {
    const dimId = dimField.product_dimension_id;
    const dim = dimsById[dimId];

    return dim.dimension_type === 'USER';
  });
  const existingUserDimFieldIds = existingUserDimFields.map(dimField => dimField.dataset_field_id);
  const keptUserDimFieldIds = new Set(
    userDimFields.map(dimField => dimField.dataset_field_id).filter(dimFieldId => dimFieldId != null),
  );
  const deletedDimFieldIds = existingUserDimFieldIds.filter(dimFieldId => !keptUserDimFieldIds.has(dimFieldId));

  return {
    userDimFieldsToInsert: dimFieldsWithoutIds,
    userDimFieldsToUpdate: dimFieldsWithIds,
    userDimFieldsIdsToDelete: deletedDimFieldIds,
    associatedDimIds,
  };
};

/**
 * Determine which automatically generated (i.e. "behind the scenes") fields need to be created/updated/deleted
 */
const constructAutomaticDimFields = async (existingDimFields, timezoneSelected, dimensions, relevantDimensionTypes) => {
  const automaticDimFieldsToInsert = [];
  const automaticDimFieldIdsToDelete = [];
  const associatedDimIds = [];

  const dimsById = _.keyBy(dimensions, 'product_dimension_id');
  const relevantDimensions = dimensions.filter(dim => relevantDimensionTypes.includes(dim.dimension_type));

  const existingAutomaticDimFields = existingDimFields.filter(dimField => {
    const dimId = dimField.product_dimension_id;
    const dim = dimsById[dimId];

    return dim.dimension_type !== 'USER';
  });

  const existingHourDimField = existingDimFields.find(({ product_dimension_id: dimId }) => {
    const dim = dimsById[dimId];
    return dim.dimension_type === HOUR_DIMENSION_TYPE;
  });
  const hasExistingHourDimField = existingHourDimField != null;

  if (timezoneSelected) {
    const existingHourDimension = dimensions.find(dim => dim.dimension_type === HOUR_DIMENSION_TYPE && dim.derived);

    if (!hasExistingHourDimField) {
      const hourDimField = await buildHourDimField(existingHourDimension?.product_dimension_id);
      automaticDimFieldsToInsert.push(hourDimField);
      associatedDimIds.push(hourDimField.product_dimension_id);
    }
  } else if (hasExistingHourDimField) {
    // We need to remove this field since it's no longer needed
    automaticDimFieldIdsToDelete.push(existingHourDimField?.dataset_field_id);
  }

  const existingDerivedDimFields = existingAutomaticDimFields.filter(dimField => {
    const dimId = dimField.product_dimension_id;
    const dim = dimsById[dimId];

    return dim.derived;
  });
  const existingDerivedDimIds = existingDerivedDimFields.map(field => field.product_dimension_id);

  // Dimensions the user doesn't see but for which dataset fields may still need to be created
  const derivedDims = relevantDimensions.filter(dim => dim.derived);
  derivedDims.forEach(dim => {
    const { product_dimension_id: dimId } = dim;
    if (!existingDerivedDimIds.includes(dimId)) {
      const field = new DatasetFieldBuilder()
        .setDatasetFieldName(ConfigFieldName.PRODUCT_DIMENSION)
        .setProductDimensionId(dimId)
        .build();

      automaticDimFieldsToInsert.push(field);
      associatedDimIds.push(field.product_dimension_id);
    }
  });

  return {
    automaticDimFieldsToInsert,
    automaticDimFieldIdsToDelete,
    associatedDimIds,
  };
};

const constructConfigFieldChanges = async (fieldIds, formState, existingFields, dimensions, relevantDimensionTypes) => {
  const dimsById = _.keyBy(dimensions, 'product_dimension_id');
  const existingDimFields = existingFields.filter(
    field => field.dataset_field_name === ConfigFieldName.PRODUCT_DIMENSION,
  );

  const simpleFields = constructSimpleFields(fieldIds, formState);
  const [simpleFieldsToInsert, simpleFieldsToUpdate] = _.partition(
    simpleFields,
    field => field.dataset_field_id == null,
  );

  const {
    userDimFieldsToInsert,
    userDimFieldsToUpdate,
    userDimFieldsIdsToDelete,
    associatedDimIds: userDimIds,
  } = await constructUserDimFieldChanges(formState, existingDimFields, dimsById);

  const transactionsDateField = simpleFields.find(field => field.dataset_field_name === 'TRAN');
  const timezoneSelected = transactionsDateField?.timezone != null;

  const {
    automaticDimFieldsToInsert,
    automaticDimFieldIdsToDelete,
    associatedDimIds: automaticDimIds,
  } = await constructAutomaticDimFields(existingDimFields, timezoneSelected, dimensions, relevantDimensionTypes);

  return {
    fieldsToInsert: [...simpleFieldsToInsert, ...userDimFieldsToInsert, ...automaticDimFieldsToInsert],
    fieldsToUpdate: [...simpleFieldsToUpdate, ...userDimFieldsToUpdate],
    fieldIdsToDelete: [...userDimFieldsIdsToDelete, ...automaticDimFieldIdsToDelete],
    associatedDimIds: [...userDimIds, ...automaticDimIds],
  };
};

const useSaveFieldChanges = (existingFields, industry, dataType) => {
  const { data: dimensions = [] } = useDimensions(true);
  const { data: dimensionTypes = [] } = useDimensionTypes(dataType, industry);

  const relevantDimensionTypes = dimensionTypes.map(dimType => dimType.dimension_type);

  const addFieldsToDatasetConfig = useAddFieldsToDatasetConfig();
  const updateConfigFieldsMutation = useUpdateConfigFields();
  const deleteConfigFieldsMutation = useDeleteConfigFields();

  const saveConfigFieldChanges = async (datasetConfigId, formState, allFieldIds) => {
    const { fieldsToInsert, fieldsToUpdate, fieldIdsToDelete, associatedDimIds } = await constructConfigFieldChanges(
      allFieldIds,
      formState,
      existingFields,
      dimensions,
      relevantDimensionTypes,
    );

    const requestPromises = [];

    if (fieldsToInsert.length) {
      requestPromises.push(addFieldsToDatasetConfig.mutateAsync({ datasetConfigId, fields: fieldsToInsert }));
    }

    if (fieldsToUpdate.length) {
      requestPromises.push(updateConfigFieldsMutation.mutateAsync({ datasetConfigId, fields: fieldsToUpdate }));
    }

    if (fieldIdsToDelete.length) {
      requestPromises.push(deleteConfigFieldsMutation.mutateAsync({ datasetConfigId, fieldIds: fieldIdsToDelete }));
    }

    await Promise.allSettled(requestPromises);

    return associatedDimIds;
  };

  return { saveConfigFieldChanges };
};

const DataSourceSettings = ({ datasetConfigId, datasetId, onFinish, onBack }) => {
  const { data: datasetConfig = {}, isLoading: isConfigLoading } = useDatasetConfig(datasetConfigId);
  const { data: datasetColumnSamples = [], isLoading: isSampleLoading } = useDatasetSample(datasetId, {
    enabled: datasetId != null,
    retry: 10,
  });

  const datasetConfigName = datasetConfig.name || 'My Data Source';
  const {
    data_type: dataType,
    naics_code: naicsCode,
    ka_code: kaCode,
    industry,
    dataset_fields: datasetConfigFields,
  } = datasetConfig;

  const { saveConfigFieldChanges } = useSaveFieldChanges(datasetConfigFields ?? [], industry, dataType);

  if (isConfigLoading) {
    return <GenericLoadingPage />;
  }

  if (!isSampleLoading && datasetColumnSamples.length === 0) {
    throw new UnableToLoadSamplesError();
  }

  const Fields = DATA_TYPE_TO_FIELDS[dataType];
  const allFieldIds = React.Children.map(Fields().props.children, field => field.props.uuid);

  const onSubmit = async formState => {
    const associatedDimIds = await saveConfigFieldChanges(datasetConfigId, formState, allFieldIds);

    if (_.isFunction(onFinish)) {
      await onFinish(associatedDimIds);
    }
  };

  return (
    <HeaderAwarePage>
      {onBack && <ProgressBar activeStep={TrackedSteps.CONNECT_DATA} />}
      <div className="p-5 data-source-settings">
        <Header />

        {onBack && <PreviousStep onClick={onBack} />}

        <Heading datasetConfigName={datasetConfigName} />

        <div className="instructions-line-1">{INSTRUCTION_LINE_1}</div>
        <div className="instructions-line-2">{INSTRUCTION_LINE_2}</div>
        <div className="instructions-underline" />

        <div className="config-area-container">
          <ConfigTableContextAPI
            datasetColumnSamples={datasetColumnSamples}
            naicsCode={naicsCode}
            kaCode={kaCode}
            dataType={dataType}
            industry={industry}
            datasetConfigFields={datasetConfigFields}
          >
            <ConfigurationTable onSubmit={onSubmit} Fields={Fields} fieldIds={allFieldIds} />
          </ConfigTableContextAPI>
          <DatasetSampleTable datasetColumnSamples={datasetColumnSamples} isSampleLoading={isSampleLoading} />
        </div>
      </div>
    </HeaderAwarePage>
  );
};

export const EditDataSourceSettings = props => {
  const { dsConfigIdToEdit: datasetConfigId, fileId: datasetId } = props.location.state;

  const { data: datasetConfig = {}, isLoading: isConfigLoading, refetch: refetchConfig } = useDatasetConfig(
    datasetConfigId,
  );
  const { data: datasetColumnSamples = [], isLoading: isSampleLoading } = useDatasetSample(datasetId, {
    enabled: datasetId != null,
    retry: 10,
  });

  const datasetConfigName = datasetConfig.name || 'My Data Source';
  const {
    dataset_fields: datasetConfigFields,
    data_type: dataType,
    naics_code: naicsCode,
    ka_code: kaCode,
    industry,
  } = datasetConfig;

  const { saveConfigFieldChanges } = useSaveFieldChanges(datasetConfigFields ?? [], industry, dataType);

  const navigateToDataCenter = useNavigateToDataCenter();

  if (isConfigLoading) {
    return <GenericLoadingPage />;
  }

  if (!isSampleLoading && datasetColumnSamples.length === 0) {
    navigateToDataCenter();
  }

  const Fields = DATA_TYPE_TO_FIELDS[dataType];
  const allFieldIds = React.Children.map(Fields().props.children, field => field.props.uuid);

  const onSubmit = async formState => {
    await saveConfigFieldChanges(datasetConfigId, formState, allFieldIds);
    await refetchConfig();
    navigateToDataCenter();
  };

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label="Data Center" />
      <div className="p-5 data-source-settings">
        <LinkWithDirectionalArrow
          className="mb-4"
          target="/data-sources"
          text="Back To Data Center"
          direction="left"
          arrowRelativeToText="left"
        />

        <Heading datasetConfigName={datasetConfigName} />

        <Tabs defaultActiveKey="settings" id="uncontrolled-tab-example" className="settings-tabs mt-4 mb-3">
          <Tab eventKey="settings" title="Data Source Settings">
            <div className="instructions-line-1 pb-1">{INSTRUCTION_LINE_1}</div>
            <div className="instructions-line-2 pb-1">{INSTRUCTION_LINE_2}</div>
            <div className="instructions-underline mb-3" />

            <div className="config-area-container">
              <ConfigTableContextAPI
                industry={industry}
                dataType={dataType}
                datasetColumnSamples={datasetColumnSamples}
                naicsCode={naicsCode}
                kaCode={kaCode}
                datasetConfigFields={datasetConfigFields}
              >
                <ConfigurationTable onSubmit={onSubmit} Fields={Fields} fieldIds={allFieldIds} />
              </ConfigTableContextAPI>
              <DatasetSampleTable datasetColumnSamples={datasetColumnSamples} isSampleLoading={isSampleLoading} />
            </div>
          </Tab>
          <Tab eventKey="datasets" title="Datasets">
            <div className="instructions-line-1 pb-1">Manage your uploaded datasets here.</div>

            <ManageDatasetsTable datasetConfigId={datasetConfigId} />
          </Tab>
        </Tabs>
      </div>
    </HeaderAwarePage>
  );
};

export const DataSourceSettingsFromDataSources = props => {
  const { dsConfigIdToEdit: datasetConfigId, fileId: datasetId } = props.location.state;

  const updateDatasetConfig = useUpdateDatasetConfig();

  const history = useHistory();

  return (
    <DataSourceSettingsWrapper
      datasetConfigId={datasetConfigId}
      datasetId={datasetId}
      onFinish={async () => {
        await updateDatasetConfig.mutateAsync({
          datasetConfigId,
          datasetConfig: { is_editable: false },
        });

        history.push('/data-sources');
      }}
    />
  );
};

export const DataSourceSettingsFromGuidedSetup = () => {
  const history = useHistory();
  const guidedSetupQuery = useGuidedSetup();
  const { data: guidedSetup = { naics_industry: {} }, isFetching, isSuccess } = guidedSetupQuery;
  const {
    dataset_config_id: datasetConfigId,
    dataset_id: datasetId,
    naics_industry: { naics_code: naicsCode, ka_code: kaCode },
    workflow_id: workflowId,
    price_change_frequencies: priceChangeFrequencies,
    data_source: { source },
    auto_generated_ids: autoGeneratedIds,
  } = guidedSetup;

  const createWorkflow = useCreateWorkflow();
  const { updateGuidedSetup } = useUpdateGuidedSetupState();
  const updateDatasetConfig = useUpdateDatasetConfig();
  const isMissingDatasetIdOrDatasetConfigId = datasetId == null || datasetConfigId == null;
  const deleteConfigFieldsMutation = useDeleteConfigFields();
  const deleteLocationMutation = useDeleteLocation();

  if (isFetching && (!isSuccess || isMissingDatasetIdOrDatasetConfigId)) {
    return <GenericLoadingPage />;
  }

  if (!isFetching && isMissingDatasetIdOrDatasetConfigId) {
    history.push('/guided-setup');
  }

  return (
    <DataSourceSettingsWrapper
      datasetConfigId={datasetConfigId}
      datasetId={datasetId}
      onBack={async () => {
        if (dataSourceSupportsDedicatedFileUpload(source)) {
          const { fields, location } = autoGeneratedIds;

          if (fields != null && fields.length > 0) {
            await deleteConfigFieldsMutation.mutateAsync({ datasetConfigId, fieldIds: fields });
          }

          if (location != null) {
            await deleteLocationMutation.mutateAsync({ locationId: location });
          }

          await updateGuidedSetup({ auto_generated_ids: {} });

          history.push(`/guided-setup/manual/file-upload/${source?.toLowerCase() ?? ''}`, {
            preventAutoForward: true,
          });
        } else {
          history.push('/guided-setup/manual/file-upload-success');
        }
      }}
      onFinish={async fieldDimensionIds => {
        const noWorkflowCreated = workflowId == null;

        let createdWorkflowId;
        if (noWorkflowCreated) {
          ({ id: createdWorkflowId } = await createWorkflow.mutateAsync({
            objective: Objective.PRICEOPT,
            description: WorkflowDescription.PRICEOPT,
            dimensions: fieldDimensionIds,
            naics_code: naicsCode,
            ka_code: kaCode,
            time_granularity: 'DAY',
            price_changes: priceChangeFrequencies,
          }));
        }

        await Promise.all([
          updateDatasetConfig.mutateAsync({
            datasetConfigId,
            datasetConfig: { is_editable: false },
          }),
          updateGuidedSetup({ is_complete: true, workflow_id: createdWorkflowId }),
        ]);

        history.push('/welcome', { showCompletedGuidedSetupModal: true });
      }}
    />
  );
};
