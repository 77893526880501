/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

export const DATASET_DATE_FORMAT = 'YYYY-MM-DD';
const SIXTY_SECONDS = 60;

/**
 * @returns An Array of date strings between "startDate" and "endDate", inclusive.
 */
export const generateDatesBetween = (startDate, endDate, dateFormat = DATASET_DATE_FORMAT) => {
  const parsedStartDate = moment(startDate, dateFormat);
  const parsedEndDate = moment(endDate, dateFormat);

  if (parsedStartDate.isAfter(parsedEndDate)) {
    throw new Error('Developer error: Start Date is after End Date');
  }

  const result = [];

  const getOutputFormat = mmt => mmt.format(dateFormat);

  while (parsedEndDate.isAfter(parsedStartDate, 'd')) {
    result.push(getOutputFormat(parsedStartDate));
    parsedStartDate.add(1, 'd');
  }

  result.push(getOutputFormat(parsedEndDate));

  return result;
};

/**
 * Supports UTC-time inputs like "2022-02-07T19:42:13.190Z" and "2022-03-04 13:02:13"
 */
export const timestampTextRelativeToNow = timestamp => {
  const now = moment.utc();
  const then = moment.utc(timestamp);

  const elapsedTimeInSeconds = Math.abs(now.diff(then, 'seconds'));

  if (elapsedTimeInSeconds < SIXTY_SECONDS) {
    return 'now';
  }
  return moment
    .utc(timestamp)
    .local()
    .fromNow();
};

export const getMonthOfDate = date => moment(date, DATASET_DATE_FORMAT).month();
export const getFormattedMonthOfDate = (date, format) => moment(date, DATASET_DATE_FORMAT).format(format);

export const getQuarterOfDate = date => moment(date, DATASET_DATE_FORMAT).quarter();
export const getYearOfDate = date => moment(date, DATASET_DATE_FORMAT).year();

export const DATE_RANGE_SELECTION = {
  NEXT_7_DAYS: 'next7',
  NEXT_30_DAYS: 'next30',
  NEXT_90_DAYS: 'next90',
  NEXT_365_DAYS: 'next365',
  CURRENT_MONTH: 'currentMonth',
  CURRENT_QUARTER: 'currentQuarter',
  CURRENT_YEAR: 'currentYear',
  NEXT_MONTH: 'nextMonth',
  NEXT_QUARTER: 'nextQuarter',
  NEXT_YEAR: 'nextYear',
};

export const DATE_RANGE_TO_LABEL = {
  [DATE_RANGE_SELECTION.NEXT_7_DAYS]: 'Next 7 Days',
  [DATE_RANGE_SELECTION.NEXT_30_DAYS]: 'Next 30 Days',
  [DATE_RANGE_SELECTION.NEXT_90_DAYS]: 'Next 90 Days',
  [DATE_RANGE_SELECTION.NEXT_365_DAYS]: 'Next 365 Days',
  [DATE_RANGE_SELECTION.CURRENT_MONTH]: 'This Calendar Month',
  [DATE_RANGE_SELECTION.CURRENT_QUARTER]: 'This Calendar Quarter',
  [DATE_RANGE_SELECTION.CURRENT_YEAR]: 'This Calendar Year',
  [DATE_RANGE_SELECTION.NEXT_MONTH]: 'Next Calendar Month',
  [DATE_RANGE_SELECTION.NEXT_QUARTER]: 'Next Calendar Quarter',
  [DATE_RANGE_SELECTION.NEXT_YEAR]: 'Next Calendar Year',
};

export const formatDateRangeLabel = dateRange => {
  const label = DATE_RANGE_TO_LABEL[dateRange].toLowerCase();

  if (label && label.startsWith('next')) {
    return `the ${label}`;
  }

  return label;
};

export const calendarMonths = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const calendarDOW = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
