import _, { uniqueId } from 'lodash';
import { addDays, format, startOfToday, parse, startOfDay } from 'date-fns';
import { PropTypes } from 'prop-types';
import React from 'react';
import { DEMAND_METRIC, GROSS_SALES_PLUS_TIPS_METRIC, INCOME_METRIC, TIPS_METRIC } from './atAGlanceTabConstants';
import AtAGlanceTabContext from './atAGlanceTabContext';
import BarChart, { computeBoxColor } from './charts/BarChart';
import { getDimColumnKey } from './revenueUpliftDashboardComputations';
import { FULL_DATE_FORMAT, LOCATIONS_DIMENSION_ID } from './revenueUpliftConstants';
import { DashboardContext, DomainContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import LoadingIndicator from '../../../accountSettings/common/LoadingIndicator';
import { Country } from '../../../generic/countryConstants';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import Measured from '../../../generic/Measured';
import { compactFormatNumber, convertTo12HourFormat } from '../../../util/format';
import { HighTempIcon, LowTempIcon, useConditions, WeatherIcon } from '../../../weather/WeatherIcons';
import { HOUR_DIMENSION_TYPE } from '../../../workflow/workflowConstants';
import { FONT_GRAY } from '../../../../colors';
import { useCustomEventsWithFullRanges } from '../../../../data-access/query/customEvents';
import { Units, useWeatherForLocation } from '../../../../data-access/query/kdl/weather';
import { gaEmitLocationDropdownClick, gaEmitViewTopByDropdownClick } from '../../../../google-analytics/atAGlanceTab';

const TemperatureType = {
  LOW: 'LOW',
  HIGH: 'HIGH',
};

const Precipitation = ({ amount, units = Units.IMPERIAL }) => {
  const unit = `${units === Units.IMPERIAL ? 'in' : 'cm'}`;
  amount = Number.isFinite(amount) ? amount.toFixed(1) : '-';

  return (
    <div className="precipitation d-flex">
      Precipitation {amount} {unit}
    </div>
  );
};
Precipitation.propTypes = {
  amount: PropTypes.number,
  units: PropTypes.oneOf(Object.values(Units)),
};
Precipitation.defaultProps = {
  amount: undefined,
  units: Units.IMPERIAL,
};

const TemperatureReadout = ({ temperature, highOrLow, units = Units.IMPERIAL }) => {
  const unit = `${units === Units.IMPERIAL ? 'F' : 'C'}`;

  let Icon;
  let temperatureType;
  if (highOrLow === TemperatureType.LOW) {
    Icon = LowTempIcon;
    temperatureType = 'Low';
  } else if (highOrLow === TemperatureType.HIGH) {
    Icon = HighTempIcon;
    temperatureType = 'High';
  }

  if (!Number.isFinite(temperature)) {
    temperature = '? ';
  }

  return (
    <div className="d-flex align-items-center flex-fill">
      {Icon && <Icon className="mr-2" />}
      <div className="d-flex flex-column">
        <div className="temp-type">{temperatureType}</div>
        <div className="temp">
          {temperature}° {unit}
        </div>
      </div>
    </div>
  );
};
TemperatureReadout.propTypes = {
  temperature: PropTypes.number.isRequired,
  highOrLow: PropTypes.oneOf(Object.values(TemperatureType)).isRequired,
  units: PropTypes.oneOf(Object.values(Units)),
};
TemperatureReadout.defaultProps = {
  units: Units.IMPERIAL,
};

const countryCodeIsUnitedStates = location => location.country_code === Country.US;

// Dates are provided with time set to Noon UTC, but for lookups we want datetime found at
// midnight in the browser's local time. Dates arrive in the form: "2023-01-31 12:00"
export const dateTimeStrToJavaScriptKey = (dateTimeStr, fillInDate) => {
  const [datePart] = dateTimeStr.split(' ');
  return parse(datePart, FULL_DATE_FORMAT, fillInDate);
};

const WeatherForecast = ({ selectedDate, selectedLocation }) => {
  const units = selectedLocation && countryCodeIsUnitedStates(selectedLocation) ? Units.IMPERIAL : Units.METRIC;

  const { data: weatherData, isLoading: isLoadingWeather, isSuccess: isSuccessWeather } = useWeatherForLocation(
    selectedLocation?.kdl_weather_location_id,
    {
      units,
    },
  );

  const selectedWeather = React.useMemo(() => {
    const fillInDate = startOfToday();

    const weatherKeyedByDate = _.keyBy(weatherData, datum => {
      const dateTimeStr = datum.forecast_datetime;
      return dateTimeStrToJavaScriptKey(dateTimeStr, fillInDate);
    });

    const chosenDate = startOfDay(selectedDate);

    const selectedForecast = weatherKeyedByDate[chosenDate] ?? {};
    const nextDayForecast = weatherKeyedByDate[addDays(chosenDate, 1)] ?? {};

    /**
     * Due to the way our weather forecast provider stores information, we have to extract
     * highs and lows for a given day according to this custom logic.
     */
    selectedForecast.lowTemp = nextDayForecast.temp_min;
    selectedForecast.highTemp = selectedForecast.temp_max;

    return selectedForecast;
  }, [selectedDate, weatherData]);

  let { cloud_coverage_pct: cloudCoverPct, rain_amt: rainAmt, snow_amt: snowAmt, lowTemp, highTemp } = selectedWeather;

  cloudCoverPct = cloudCoverPct ?? 0;
  rainAmt = rainAmt ?? 0.0;
  snowAmt = snowAmt ?? 0.0;
  highTemp = Math.round(highTemp);
  lowTemp = Math.round(lowTemp);

  const { conditions, conditionsLabel } = useConditions(cloudCoverPct, rainAmt, snowAmt);

  const precipitationAmt = rainAmt + snowAmt;

  if (isLoadingWeather) {
    return <LoadingIndicator />;
  }
  if (!isSuccessWeather || selectedWeather == null) {
    return (
      <i className="d-flex align-items-center justify-content-center" style={{ color: FONT_GRAY }}>
        Aw snap...
      </i>
    );
  }

  return (
    <>
      <div className="divided-section d-flex flex-fill">
        <div className="d-flex align-items-center justify-content-center" style={{ minWidth: 80 }}>
          <WeatherIcon conditions={conditions} />
        </div>
        <div className="d-flex flex-column">
          <div className="conditions">{conditionsLabel}</div>
          <Precipitation amount={precipitationAmt} units={units} />
        </div>
      </div>
      <div className="divided-section flex-fill d-flex">
        <TemperatureReadout temperature={highTemp} highOrLow={TemperatureType.HIGH} units={units} />
        <TemperatureReadout temperature={lowTemp} highOrLow={TemperatureType.LOW} units={units} />
      </div>
    </>
  );
};

const HolidaysList = ({ selectedDate, selectedLocation }) => {
  const { holidaysKeyedByDate, isLoadingHolidayData } = React.useContext(AtAGlanceTabContext);

  const { data: customEventsData = [], isLoading: isLoadingCustomEventsData } = useCustomEventsWithFullRanges();

  const eventsKeyedByDate = React.useMemo(() => {
    const customEventsForLocation = customEventsData.filter(
      event => event.location_id === selectedLocation.location_id,
    );

    return _.groupBy(customEventsForLocation, 'eventDate');
  }, [customEventsData, selectedLocation.location_id]);

  const selectedEvents = React.useMemo(() => {
    const locationHolidays = (holidaysKeyedByDate[selectedDate] ?? []).filter(
      h => h.countryCode === selectedLocation.country_code && (!h.stateCode || h.stateCode === selectedLocation.state),
    );
    const locationEvents = eventsKeyedByDate[selectedDate] ?? [];
    const allEvents = [...locationHolidays, ...locationEvents];

    return _.uniq(allEvents.map(h => h.description));
  }, [holidaysKeyedByDate, selectedLocation, selectedDate, eventsKeyedByDate]);

  let eventsText = 'None';
  if (isLoadingHolidayData || isLoadingCustomEventsData) {
    eventsText = <LoadingIndicator />;
  } else if (selectedEvents.length > 0) {
    eventsText = selectedEvents.join(', ');
  }

  return eventsText;
};

const WeatherAndHolidaysCard = ({ selectedDate, selectedLocation }) => (
  <ProfitRoverCard className="weather-and-holidays bubble pt-2 pb-3 px-3">
    <div className="bubble-heading">Weather and Holidays</div>

    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center justify-content-center w-100" style={{ minHeight: 100 }}>
        <WeatherForecast selectedDate={selectedDate} selectedLocation={selectedLocation} />
      </div>
      <div className="border w-100 mt-1 mb-3" />
      <div className="d-flex justify-content-start w-100">
        <span className="holidays-and-events-label mr-2">Holidays / Events:</span>
        <div className="holidays-and-events-list">
          <HolidaysList selectedDate={selectedDate} selectedLocation={selectedLocation} />
        </div>
      </div>
    </div>
  </ProfitRoverCard>
);

const TOP_FIVE = 5;

const EmptyBreakdownCard = ({ message }) => {
  return (
    <ProfitRoverCard className="breakdown bubble pt-2 pb-3 px-3">
      <div className="d-flex flex-column align-items-start h-100 w-100">
        <div className="bubble-heading">Breakdown</div>
        <div
          className="flex-fill d-flex align-items-center justify-content-center w-100 font-weight-light"
          style={{ color: FONT_GRAY, fontSize: '0.875rem' }}
        >
          <span>{message}</span>
        </div>
      </div>
    </ProfitRoverCard>
  );
};

const BreakdownCard = ({
  selectedRows,
  selectedStatsRows,
  selectedDim,
  setSelectedDim,
  selectableDims,
  isPriceOptimizationProfit,
}) => {
  const { currencySymbol, getDimValueId, getDimValueLabel, is24HourFormat } = React.useContext(DashboardContext);
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  const selectedDimId = selectedDim.product_dimension_id;
  const isHourDimSelected = selectedDim.dimension_type === HOUR_DIMENSION_TYPE;

  const rowsByDimValueId = _.groupBy(selectedRows, getDimColumnKey(selectedDimId));
  const statsRowsByDimValueId = _.groupBy(selectedStatsRows, getDimColumnKey(selectedDimId));

  let data = Object.entries(rowsByDimValueId).map(([dimValueId, rows]) => {
    const id = getDimValueId(selectedDimId, dimValueId) ?? uniqueId();
    const name = getDimValueLabel(selectedDimId, dimValueId) ?? id ?? 'Unknown';
    const currentIncomeMetric = isPriceOptimizationProfit ? 'currentProfit' : 'currentRevenue';
    return {
      id,
      revenue: _.sumBy(rows, currentIncomeMetric),
      demand: _.sumBy(rows, 'currentDemand'),
      name,
    };
  });
  let statsData = Object.entries(statsRowsByDimValueId).map(([dimValueId, rows]) => {
    const id = getDimValueId(selectedDimId, dimValueId) ?? uniqueId();
    const name = getDimValueLabel(selectedDimId, dimValueId) ?? id ?? 'Unknown';
    return {
      id,
      tips: _.sumBy(rows, 'prediction'),
      name,
    };
  });

  const tipsSum = _.sumBy(statsData, 'tips');

  // If “Gross Sales + Tips” is selected list “Tips” as its own bar in the table
  if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC && tipsSum > 0) {
    data.push({
      id: 'tips',
      revenue: tipsSum,
      demand: 0,
      name: 'Tips',
    });
  }
  data = _.orderBy(data, 'revenue', 'desc');
  data = _.take(data, TOP_FIVE);

  statsData = _.orderBy(statsData, 'tips', 'desc');
  statsData = _.take(statsData, TOP_FIVE);

  // Add ordinal position to each dim value for color consistency
  data.forEach((val, i) => {
    val.rank = i;
  });
  statsData.forEach((val, i) => {
    val.rank = i;
  });

  // Both graphs are ordered descending by their constituent metric, but color is determined by revenue order
  const revenueData = _.orderBy(data, 'revenue', 'desc');
  const demandData = _.orderBy(data, 'demand', 'desc');
  const tipsData = _.orderBy(statsData, 'tips', 'desc');

  const showDropdown = selectableDims.length > 1;

  const getChartData = () => {
    switch (selectedMetric.value) {
      case INCOME_METRIC:
      case GROSS_SALES_PLUS_TIPS_METRIC:
        return {
          data: revenueData,
          seriesKey: 'revenue',
          tickFormat: value =>
            compactFormatNumber(value, { formatAsCurrency: true, currencySymbol, fixedDecimalDigits: 1 }),
        };
      case DEMAND_METRIC:
        return {
          data: demandData,
          seriesKey: 'demand',
          tickFormat: value => compactFormatNumber(value, { formatAsCurrency: false, fixedDecimalDigits: 1 }),
        };
      case TIPS_METRIC:
        return {
          data: tipsData,
          seriesKey: 'tips',
          tickFormat: value =>
            compactFormatNumber(value, { formatAsCurrency: true, currencySymbol, fixedDecimalDigits: 1 }),
        };
      default:
        return {
          data: [],
          seriesKey: '',
          tickFormat: () => '',
        };
    }
  };

  const chartData = getChartData();
  const noDataAvailable = chartData.data.length === 0 || chartData.data[0][chartData.seriesKey] === 0;

  if (!chartData || noDataAvailable) {
    return <EmptyBreakdownCard message={`No ${selectedMetric.label}`} />;
  }

  const legendData = selectedMetric.value === TIPS_METRIC ? statsData : data;

  const onDimChange = dim => {
    gaEmitViewTopByDropdownClick();
    setSelectedDim(dim);
  };

  return (
    <ProfitRoverCard className="breakdown bubble pt-2 pb-3 px-3">
      <div className="d-flex align-items-center">
        <div className="bubble-heading">Breakdown</div>
        {showDropdown ? (
          <>
            <div className="view-top-by ml-4 mr-2">View Top By:</div>
            <div style={{ minWidth: 200 }}>
              <RevenueUpliftSelect
                options={selectableDims}
                onChange={onDimChange}
                getOptionLabel={dim => dim.name}
                getOptionValue={_.identity}
                value={selectedDim}
              />
            </div>
          </>
        ) : (
          <div className="view-top-by ml-4">View Top By {selectedDim?.name ?? ''}</div>
        )}
      </div>
      <div className="d-flex w-100">
        <div className="flex-fill" style={{ minWidth: 0 }}>
          <Measured>
            {({ height, width }) => (
              <BarChart
                data={chartData.data}
                seriesKey={chartData.seriesKey}
                tickFormat={chartData.tickFormat}
                height={height}
                width={width}
              />
            )}
          </Measured>
        </div>
        <div className="legend pt-4 px-4">
          {legendData.map(({ name, rank }) => (
            <div key={name} className="d-flex align-items-center mb-2">
              <div className="color-box mr-1" style={{ backgroundColor: computeBoxColor(rank) }} />
              <div className="legend-label">
                {isHourDimSelected && !is24HourFormat ? convertTo12HourFormat(name) : name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ProfitRoverCard>
  );
};

const DayDetailsSection = ({ sevenDayForecastRows, weeklyForecastStatsRows, selectedDate, collapseProps }) => {
  const { locations, dimensions, isPriceOptimizationProfit } = React.useContext(DomainContext);
  const { selectedMetric, sortedLocations } = React.useContext(AtAGlanceTabContext);

  const sortedDims = React.useMemo(() => {
    if (selectedMetric.value === TIPS_METRIC) {
      return dimensions.filter(dim => dim.dimension_type === HOUR_DIMENSION_TYPE);
    }
    return _.sortBy(dimensions, 'name');
  }, [dimensions, selectedMetric]);

  const [selectedLocation, setSelectedLocation] = React.useState(sortedLocations[0]);
  const [selectedDim, setSelectedDim] = React.useState(sortedDims[0]);

  React.useEffect(() => {
    const selectedLocationId = selectedLocation.location_id;
    const selectableLocationIds = sortedLocations.map(loc => loc.location_id);

    // If the user filters outs the currently selected location, automatically select the first in the remaining list
    if (!selectableLocationIds.includes(selectedLocationId)) {
      setSelectedLocation(sortedLocations[0]);
    }
  }, [selectedLocation.location_id, sortedLocations]);

  React.useEffect(() => {
    if (sortedDims.length > 0) {
      setSelectedDim(sortedDims[0]);
    }
  }, [sortedDims]);

  const onLocationChange = location => {
    gaEmitLocationDropdownClick();
    setSelectedLocation(location);
  };

  const dayOfWeek = format(selectedDate, 'EEE');
  const dayOfMonth = format(selectedDate, 'MMM	d');

  const [selectedRows, selectedStatsRows] = React.useMemo(() => {
    const fillInDate = startOfToday();

    const rowsForLocation = sevenDayForecastRows.filter(row => {
      const locationColumnKey = getDimColumnKey(LOCATIONS_DIMENSION_ID);
      return row[locationColumnKey] === selectedLocation.location_id;
    });
    const selectedRowsByDate = _.groupBy(rowsForLocation, row => dateTimeStrToJavaScriptKey(row.tranDate, fillInDate));
    const rows = selectedRowsByDate[selectedDate] ?? [];

    const statsRowsForLocation = weeklyForecastStatsRows.filter(row => {
      const locationColumnKey = getDimColumnKey(LOCATIONS_DIMENSION_ID);
      return row[locationColumnKey] === selectedLocation.location_id;
    });
    const selectedStatsRowsByDate = _.groupBy(statsRowsForLocation, row =>
      dateTimeStrToJavaScriptKey(row.tranDate, fillInDate),
    );
    const statsRows = selectedStatsRowsByDate[selectedDate] ?? [];

    return [rows, statsRows];
  }, [selectedLocation.location_id, selectedDate, sevenDayForecastRows, weeklyForecastStatsRows]);

  return (
    <div className="d-flex flex-column mt-2 day-details" {...collapseProps}>
      <div className="d-flex mt-4 mb-2">
        <h5 className="mb-0">Details for</h5>
        <span
          className="date-box d-flex align-items-center justify-content-between px-2 ml-2 word-no-wrap"
          style={{ minWidth: 100 }}
        >
          <strong className="day-of-week">{dayOfWeek}</strong>
          <div className="day-of-month">{dayOfMonth}</div>
        </span>

        {locations.length > 1 && (
          <>
            <div className="border mx-3" />

            <div className="d-flex align-items-center">
              <div className="location-label mr-2">Location:</div>
              <div style={{ minWidth: 180 }}>
                <RevenueUpliftSelect
                  options={sortedLocations}
                  getOptionLabel={location => location.location_description}
                  getOptionValue={_.identity}
                  onChange={onLocationChange}
                  value={selectedLocation}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="day-details-section p-2">
        <div className="d-flex flex-md-wrap" style={{ columnGap: '0.75rem', rowGap: '0.75rem' }}>
          <WeatherAndHolidaysCard selectedDate={selectedDate} selectedLocation={selectedLocation} />
          <BreakdownCard
            selectedRows={selectedRows}
            selectedStatsRows={selectedStatsRows}
            selectedDim={selectedDim}
            setSelectedDim={setSelectedDim}
            selectableDims={sortedDims}
            isPriceOptimizationProfit={isPriceOptimizationProfit}
          />
        </div>
      </div>
    </div>
  );
};

export default DayDetailsSection;
