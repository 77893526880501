import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { endsOnNextDay, isValidHour } from './locationHoursOfOperationUtil';
import { ALL_HOURS, CLOSE_TIME, OPEN_TIME, IS_CLOSED, OPEN_24_HOURS } from './locationManagementConstants';
import { convertTo12HourFormat } from '../../util/format';

const hourRegex = /^$|^(\d{1,2}):?(\d{0,2})?\s?(A|P|AM|PM)?$/;
const LABEL_24_HOURS = '24 hrs';

const formatHour = (hour, is24HourFormat) => {
  if (!hour || hour === LABEL_24_HOURS) {
    return hour;
  }
  return is24HourFormat ? hour : convertTo12HourFormat(hour);
};

const getValue = (dayHoursOfOperation, field, is24HourFormat) => {
  let returnValue = dayHoursOfOperation?.[field];

  if (field === OPEN_TIME) {
    returnValue = dayHoursOfOperation[OPEN_24_HOURS] === true ? LABEL_24_HOURS : dayHoursOfOperation[OPEN_TIME];
  }

  if (returnValue == null || returnValue === '') {
    return '';
  }

  if (returnValue === LABEL_24_HOURS) {
    return returnValue;
  }

  return is24HourFormat ? returnValue : convertTo12HourFormat(returnValue);
};

const convertTo24HourFormat = time12h => {
  const match = time12h.match(/^(\d{1,2}):(\d{2}) (AM|PM)$/);
  if (!match) {
    return time12h;
  } // Return as-is if format is invalid

  const hours = parseInt(match[1], 10); // Extract hours
  const minutes = match[2]; // Extract minutes (already a string)
  const period = match[3]; // Extract AM/PM

  let hours24 = hours;

  if (period === 'PM' && hours !== 12) {
    hours24 += 12; // Convert PM hours
  }
  if (period === 'AM' && hours === 12) {
    hours24 = 0; // Midnight edge case
  }

  return `${hours24.toString().padStart(2, '0')}:${minutes}`;
};

const HourField = ({
  dayHoursOfOperation,
  isLoading,
  field,
  setLocationHoursOfOperation,
  errorFields,
  hideClearButton = false,
  validateHour = false,
  is24HourFormat = true,
}) => {
  const [currentValue, setCurrentValue] = React.useState(getValue(dayHoursOfOperation, field, is24HourFormat));
  const [showOptions, setShowOptions] = React.useState(false);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const newValue = getValue(dayHoursOfOperation, field, is24HourFormat);
    setCurrentValue(newValue);
  }, [dayHoursOfOperation, field, is24HourFormat]);

  const options = React.useMemo(() => {
    const formattedOptions = field === OPEN_TIME ? [LABEL_24_HOURS, ...ALL_HOURS] : ALL_HOURS;
    return formattedOptions.map(option => formatHour(option, is24HourFormat));
  }, [field, is24HourFormat]);

  const getNewValue = value => {
    const otherField = field === OPEN_TIME ? CLOSE_TIME : OPEN_TIME;

    // Convert to 24-hour format for internal consistency
    const internalValue = is24HourFormat ? value : convertTo24HourFormat(value);

    let newValue = {
      [OPEN_24_HOURS]: false,
      [IS_CLOSED]: false,
      [field]: internalValue,
      [otherField]: dayHoursOfOperation[otherField],
    };

    if (internalValue === LABEL_24_HOURS && field === OPEN_TIME) {
      newValue = {
        [OPEN_24_HOURS]: true,
        [IS_CLOSED]: false,
        [OPEN_TIME]: '',
        [CLOSE_TIME]: '',
      };
    } else if (internalValue === '00:00' && dayHoursOfOperation[otherField] === '00:00') {
      newValue = {
        [IS_CLOSED]: false,
        [OPEN_24_HOURS]: true,
        [OPEN_TIME]: '',
        [CLOSE_TIME]: '',
      };
    }

    return newValue;
  };

  const setValue = value => {
    const newValue = getNewValue(value);

    if (!validateHour || newValue[IS_CLOSED] || newValue[OPEN_24_HOURS] || isValidHour(newValue[field])) {
      setLocationHoursOfOperation(dayHoursOfOperation.day, newValue);
    } else {
      setCurrentValue(getValue(dayHoursOfOperation, field, is24HourFormat));
    }
  };

  const handleSetValue = () => {
    if (!currentValue || currentValue.trim() === '') {
      setValue('');
      return;
    }
    setValue(currentValue);
  };

  const handleInputChange = event => {
    const { value } = event.target;
    if (hourRegex.test(value)) {
      setCurrentValue(value);
    }
  };

  const handleInputFocus = () => {
    setShowOptions(true);
  };

  const selectOption = option => {
    const value = is24HourFormat ? option : ALL_HOURS.find(hour => convertTo12HourFormat(hour) === option);
    setValue(value || option);
    setShowOptions(false);
  };

  const onBlur = () => {
    if (showOptions === false) {
      // Field was not active
      return;
    }

    handleSetValue();
  };

  const onClear = () => {
    const newValue = getNewValue('');
    setLocationHoursOfOperation(dayHoursOfOperation.day, newValue);
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onBlur();
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNextDay =
    field === CLOSE_TIME && endsOnNextDay(dayHoursOfOperation[OPEN_TIME], dayHoursOfOperation[CLOSE_TIME]);
  const isError =
    errorFields &&
    errorFields.some(errorField => errorField.day === dayHoursOfOperation.day && errorField.field === field);
  const isHidden = dayHoursOfOperation[IS_CLOSED] || (dayHoursOfOperation[OPEN_24_HOURS] && field === CLOSE_TIME);

  const filteredOptions = options.filter(option => option.toLowerCase().includes(currentValue.toLowerCase()));

  return (
    <div className="hours-of-operation-input" ref={containerRef}>
      <input
        type="text"
        className={classnames('hour-input', { error: isError, hidden: isHidden })}
        placeholder={isHidden ? '-' : '--:--'}
        value={currentValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleSetValue}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            e.target.blur();
            setShowOptions(false);
          }
        }}
        disabled={isLoading || isHidden}
      />
      {isNextDay && <span className="next-day">Next Day</span>}
      {currentValue?.length > 0 && !hideClearButton && (
        <button type="button" className="clear-button" onClick={onClear} disabled={isLoading || isHidden} tabIndex={-1}>
          <FontAwesomeIcon icon={faXmark} className="clear-icon" />
        </button>
      )}
      {showOptions && (
        <div className="options-container">
          {filteredOptions.map(option => (
            <button type="button" className="option" key={option} onMouseDown={() => selectOption(option)}>
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default HourField;
