import React from 'react';
import { ERROR } from '../../colors';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import { useEditPreferencesMutation, USER_PREFERENCES_SUBJECT } from '../../data-access/query/preferences';

const CommunicationPreferences = ({ data }) => {
  const [weeklyEmailReport, setWeeklyEmailReport] = React.useState(data.kp_insights_weekly ?? false);
  const [isDirty, setIsDirty] = React.useState(false);

  const userId = React.useRef(localStorage.getItem('id')).current;

  const { mutate: editEmailPreferences, isLoading: isLoadingUpdate, error } = useEditPreferencesMutation(
    userId,
    USER_PREFERENCES_SUBJECT.EMAIL,
    {
      onSuccess: () => setIsDirty(false),
    },
  );

  const onChange = e => {
    setWeeklyEmailReport(e.target.checked);
    setIsDirty(true);
  };

  const saveChanges = () => {
    editEmailPreferences({
      kp_insights_weekly: weeklyEmailReport,
    });
  };

  return (
    <ProfitRoverCard className="settings-card">
      <h4 className="settings-subheader">Communication</h4>
      <div className="mb-3 d-flex align-center">
        <input
          type="checkbox"
          name="weekly_updates"
          checked={weeklyEmailReport}
          onChange={onChange}
          disabled={isLoadingUpdate}
        />
        <span className="ml-2">Send me weekly email updates about my business</span>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <ProfitRoverPrimaryButton onClick={saveChanges} disabled={!isDirty || isLoadingUpdate}>
          Save Changes
        </ProfitRoverPrimaryButton>
      </div>
      {error && (
        <div className="mt-4">
          <p style={{ fontSize: '14px', color: ERROR }}>There was an error saving your preferences. Try again later.</p>
        </div>
      )}
    </ProfitRoverCard>
  );
};

export default CommunicationPreferences;
