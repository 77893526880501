import classnames from 'classnames';
import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { Objective, PriceChangeFrequency } from './workflowConstants';
import { applyCompatiblePriceChangeFrequencyOptions } from './workflowFunctions';
import SettingsNavigationMenu from '../accountSettings/SettingsNavigationMenu';
import { PromptText } from '../forms/GenericFormComponents';
import { ProfitRoverCancelButton, ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import HeaderAwarePage from '../generic/HeaderAwarePage';
import { ProfitRoverLinearLogo } from '../generic/ProfitRoverLogos';
import Header from '../header/header';
import { CenteredProfitRoverSpinner } from '../spinner/ProfitRoverSpinner';
import { Can } from '../../casl/abilityContext';
import { Action, Subject } from '../../casl/caslConstants';
import { LIGHT_BLUE } from '../../colors';
import { updateWorkflow } from '../../data-access/mutation/workflows';
import { useDimensions } from '../../data-access/query/dimensions';
import { gaEmitSaveAndRefreshResultsButtonClick } from '../../google-analytics/updateWorkflowDimensions';
import './workflow-dimension-settings.css';

const PriceFrequencyCheckbox = props => {
  const { frequency, priceChangeFrequencyOptions, label, onChange } = props;

  const disabled = priceChangeFrequencyOptions.disabled[frequency];
  const checked = priceChangeFrequencyOptions.checked[frequency];

  return (
    <>
      <input type="checkbox" name={frequency} checked={checked} disabled={disabled} onChange={onChange} />
      <span className={classnames(disabled && 'price-change-frequency-text-disabled')}>{label}</span>
    </>
  );
};

const initializePriceChangeFrequencyState = (originalPriceChangeFrequency = []) => {
  // handle the STATIC price change frequency special case
  if (originalPriceChangeFrequency.length === 0) {
    originalPriceChangeFrequency = [PriceChangeFrequency.STATIC];
  }

  const initialCheckedState = {};
  const initialDisabledState = {};
  Object.keys(PriceChangeFrequency).forEach(priceChangeFrequency => {
    if (originalPriceChangeFrequency.includes(priceChangeFrequency)) {
      initialCheckedState[priceChangeFrequency] = true;
      initialDisabledState[priceChangeFrequency] = false;

      return;
    }

    initialCheckedState[priceChangeFrequency] = false;
    initialDisabledState[priceChangeFrequency] = false;
  });

  applyCompatiblePriceChangeFrequencyOptions(initialCheckedState, initialDisabledState);

  return { checked: initialCheckedState, disabled: initialDisabledState };
};

const UpdateWorkflowDimensionsPage = ({ workflow, dimensions }) => {
  const history = useHistory();

  const {
    workflow_id: workflowId,
    dimensions: originalDimensions,
    price_changes: originalPriceChangeFrequency,
    objective,
  } = workflow;

  const [isUpdating, setIsUpdating] = React.useState(false);
  const [selectedDimensions, setSelectedDimensions] = React.useState(() =>
    dimensions
      .filter(x => originalDimensions.includes(x.product_dimension_id))
      .map(dimension => ({ label: dimension.name, value: dimension.product_dimension_id })),
  );

  const [priceChangeFrequencyOptions, setPriceChangeFrequencyOptions] = React.useState(() =>
    initializePriceChangeFrequencyState(originalPriceChangeFrequency),
  );

  const dimensionOptions = dimensions.map(dimension => ({
    label: dimension.name,
    value: dimension.product_dimension_id,
  }));

  const selectedDimensionsArray = selectedDimensions?.map(dim => dim.value);
  const isMatchingOriginalDimensions =
    selectedDimensionsArray.length === originalDimensions.length &&
    selectedDimensionsArray.every(dim => originalDimensions.includes(dim));

  let selectedPriceChangeFrequency = Object.keys(PriceChangeFrequency).filter(
    priceChangeFrequency => priceChangeFrequencyOptions.checked[priceChangeFrequency],
  );

  const isMatchingOriginalPriceChangeFrequency =
    selectedPriceChangeFrequency.length === originalPriceChangeFrequency.length &&
    selectedPriceChangeFrequency.every(priceChangeFrequency =>
      originalPriceChangeFrequency.includes(priceChangeFrequency),
    );

  const isButtonDisabled =
    selectedDimensionsArray.length === 0 ||
    selectedPriceChangeFrequency.length === 0 ||
    (isMatchingOriginalDimensions && isMatchingOriginalPriceChangeFrequency);

  const onClickSave = async () => {
    gaEmitSaveAndRefreshResultsButtonClick();
    setIsUpdating(true);

    /**
     * The STATIC / 'One Time' price change frequency is a special case that should be set as empty when we update the
     * workflow (there is no corresponding record in the dim table for it; it is recognized by the empty value).
     */
    if (selectedPriceChangeFrequency.length === 1 && selectedPriceChangeFrequency[0] === PriceChangeFrequency.STATIC) {
      selectedPriceChangeFrequency = [];
    }

    const pricingDimensions = selectedDimensions
      .filter(selDim =>
        dimensions.find(dim => dim.product_dimension_id === selDim.value && dim.dimension_type !== 'HOUR'),
      )
      .map(dim => dim.value);

    const workflowBody = {
      dimensions: selectedDimensions.map(dim => dim.value),
      pricing_dimensions: pricingDimensions,
      price_changes: selectedPriceChangeFrequency,
    };

    const shouldRefreshResults = objective === Objective.PRICEELASTICITY;
    await updateWorkflow(workflowId, workflowBody, shouldRefreshResults);

    history.push(`/profitrover/${workflowId}`);
  };

  const onChangePriceChangeFrequency = event => {
    const modifiedPriceChangeFrequency = event.target.name;
    const toggledValue = !priceChangeFrequencyOptions.checked[modifiedPriceChangeFrequency];
    const checkedState = priceChangeFrequencyOptions.checked;
    const disabledState = priceChangeFrequencyOptions.disabled;

    checkedState[modifiedPriceChangeFrequency] = toggledValue;

    applyCompatiblePriceChangeFrequencyOptions(checkedState, disabledState);

    setPriceChangeFrequencyOptions(() => {
      return {
        ...priceChangeFrequencyOptions,
        checked: checkedState,
        disabled: disabledState,
      };
    });
  };

  const resetPriceChangeFrequency = () => {
    const resetCheckedState = {};
    const resetDisabledState = {};

    Object.keys(PriceChangeFrequency).forEach(priceChangeFrequency => {
      resetCheckedState[priceChangeFrequency] = false;
      resetDisabledState[priceChangeFrequency] = false;
    });

    setPriceChangeFrequencyOptions(() => {
      return {
        ...priceChangeFrequencyOptions,
        checked: resetCheckedState,
        disabled: resetDisabledState,
      };
    });
  };

  const commonProps = { priceChangeFrequencyOptions, onChange: onChangePriceChangeFrequency };

  return (
    <HeaderAwarePage>
      <Header />
      <SettingsNavigationMenu label="Settings" />

      <div className="update-workflow-container edit-workflow-div">
        <div className="update-workflow-settings-header">
          <ProfitRoverLinearLogo className="profitrover-logo" />
          <div className="workflow-dimensions-settings-text"> Settings</div>
        </div>
        <PromptText>Select one or more dimensions you wish to include in your analysis</PromptText>
        <div className="dimensions-drop-down">
          <Select
            options={dimensionOptions}
            isMulti
            onChange={values => {
              setSelectedDimensions(values ?? []);
            }}
            value={selectedDimensions}
          />
        </div>
        <div className="price-change-frequency-selection">
          <PromptText>How often do you want to change your price?</PromptText>
          <PriceFrequencyCheckbox {...commonProps} frequency={PriceChangeFrequency.STATIC} label="One Time" />
          <PriceFrequencyCheckbox {...commonProps} frequency={PriceChangeFrequency.DOW} label="Day of Week" />
          <PriceFrequencyCheckbox {...commonProps} frequency={PriceChangeFrequency.MONTH} label="Monthly" />
          <PriceFrequencyCheckbox {...commonProps} frequency={PriceChangeFrequency.QUARTER} label="Quarterly" />
          <PriceFrequencyCheckbox {...commonProps} frequency={PriceChangeFrequency.YEAR} label="Yearly" />
          <span className="vertical-separator" />
          <FontAwesomeIcon
            icon={faRedo}
            color={LIGHT_BLUE}
            style={{ cursor: 'pointer' }}
            onClick={resetPriceChangeFrequency}
          />
          <span
            className="reset-price-change-frequency-link"
            role="button"
            tabIndex="0"
            onClick={resetPriceChangeFrequency}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                resetPriceChangeFrequency();
              }
            }}
          >
            RESET
          </span>
        </div>
        <div className="buttons-container">
          <ProfitRoverPrimaryButton style={{ minWidth: 238 }} disabled={isButtonDisabled} onClick={onClickSave}>
            {isUpdating ? <CenteredProfitRoverSpinner /> : 'Save and Refresh Results'}
          </ProfitRoverPrimaryButton>
          <ProfitRoverCancelButton onClick={() => history.goBack()}>Cancel</ProfitRoverCancelButton>
        </div>
        <span className="workflow-refresh-notification-text">
          After saving your settings, you will receive a message in your Notification Center when your dashboard has
          finished updating. Please note that this may take some time to complete.
        </span>
      </div>
    </HeaderAwarePage>
  );
};

const PageAccessCheck = () => {
  const location = useLocation();

  const { data: dimensions = [], isLoading } = useDimensions(true);

  const workflow = location?.state?.workflow;
  const workflowExists = workflow != null;

  if (!workflowExists) {
    return <Redirect to="/welcome" />;
  }

  if (isLoading) {
    return (
      <HeaderAwarePage scrollable={false}>
        <Header />
        <CenteredProfitRoverSpinner />
      </HeaderAwarePage>
    );
  }

  return (
    <Can I={Action.EDIT} a={Subject.WORKFLOW} passThrough>
      {allowed =>
        allowed ? (
          <UpdateWorkflowDimensionsPage workflow={workflow} dimensions={dimensions} />
        ) : (
          <Redirect to="/welcome" />
        )
      }
    </Can>
  );
};

export default PageAccessCheck;
