import React from 'react';
import classnames from 'classnames';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadIcon from '../../images/download-icon.svg';
import './profitrover-buttons.css';

const ProfitRoverButtonBase = ({ children, className, small = false, type = 'button', ...props }) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={classnames('profitrover-button-base', { 'small-button': small }, className)}
    {...props}
  >
    {children}
  </button>
);

export const ProfitRoverPrimaryButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-primary-button', className)} small={small} {...props} />
);

export const ProfitRoverSecondaryButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-secondary-button', className)} small={small} {...props} />
);

export const ProfitRoverCancelButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-cancel-button', className)} small={small} {...props} />
);

export const ProfitRoverDestructiveButton = ({ className, small, ...props }) => (
  <ProfitRoverButtonBase className={classnames('profitrover-destructive-button', className)} small={small} {...props} />
);

export const ProfitRoverAddButton = ({ className, type = 'button', ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type} className={classnames('profitrover-add-button', className)} {...props}>
    <div className="alignment-container">
      <div className="plus-container">
        <span className="plus">+</span>
      </div>
      <div>{props.children}</div>
    </div>
  </button>
);

export const ProfitRoverDownloadSelectButton = ({ options, onOptionSelected, disabled = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const menuRef = React.useRef(null);

  const handleDownload = option => {
    onOptionSelected(option);
    setIsOpen(false);
  };

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="profitrover-download-select-button" ref={menuRef}>
      <ProfitRoverSecondaryButton onClick={() => setIsOpen(!isOpen)} disabled={disabled}>
        <img className="download-icon" src={DownloadIcon} alt="" />
        Download
        <span className="divider" />
        <FontAwesomeIcon className="Icon" name="caretDown" icon={faAngleDown} style={{ margin: 0 }} />
      </ProfitRoverSecondaryButton>

      {isOpen && (
        <div className="menu">
          {options.map(option => (
            <div
              key={option}
              role="button"
              tabIndex={0}
              onClick={() => handleDownload(option)}
              onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleDownload(option);
                }
              }}
              className={`menu-option ${selectedOption === option ? 'active' : ''}`}
              onMouseEnter={() => setSelectedOption(option)}
              onMouseLeave={() => setSelectedOption(null)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
