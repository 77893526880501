import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './grid.scss';

const GridHeader = ({ name, column, setSort }) => {
  const [currentSort, setCurrentSort] = React.useState(null);

  const handleSort = () => {
    const columnSort = column.getSort();
    const nextSort = columnSort === 'asc' ? 'desc' : columnSort === 'desc' ? null : 'asc';
    setSort(nextSort);
    setCurrentSort(nextSort);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSort();
    }
  };

  return (
    <div
      className="header-wrap"
      role="button"
      tabIndex={0}
      onClick={handleSort}
      onKeyDown={handleKeyDown}
      style={{ cursor: 'pointer' }}
    >
      <span>{name}</span>
      {currentSort === 'asc' && <FontAwesomeIcon className="ml-1" icon={faArrowUp} />}
      {currentSort === 'desc' && <FontAwesomeIcon className="ml-1" icon={faArrowDown} />}
    </div>
  );
};

export default GridHeader;
