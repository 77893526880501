import React from 'react';
import { getShiftEndTime } from './staffingTabUtil';

const HourEditor = React.forwardRef((props, ref) => {
  const {
    type,
    data: { start, end },
    context: { hoursRange },
  } = props;

  const inputRef = React.useRef();

  const initialState = type === 'start' ? start : getShiftEndTime(end, hoursRange);
  const [value, setValue] = React.useState(initialState);

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      const [hours, minutes = '00'] = value.split(':');
      const formattedHours = hours.padStart(2, '0');
      const formattedMinutes = minutes === '30' ? '30' : '00';
      return `${formattedHours}:${formattedMinutes}`;
    },
  }));

  const handleChange = e => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <div ref={ref} className="staffing-editor">
      <input ref={inputRef} type="text" value={value} onChange={handleChange} />
    </div>
  );
});

HourEditor.displayName = 'HourEditor';

export default HourEditor;
