import React from 'react';
import { DISPLAY_TIME_FORMAT, usePreferences, USER_PREFERENCES_SUBJECT } from '../data-access/query/preferences';

const useHourFormat = () => {
  const userId = React.useRef(localStorage.getItem('id')).current;
  const [is24HourFormat, setIs24HourFormat] = React.useState(true);

  const { data: displayPreferences = {}, isLoading } = usePreferences(userId, USER_PREFERENCES_SUBJECT.DISPLAY);

  React.useEffect(() => {
    if (displayPreferences?.hour_format) {
      setIs24HourFormat(displayPreferences.hour_format === DISPLAY_TIME_FORMAT.FORMAT_24_HOUR);
    }
  }, [displayPreferences]);

  return { is24HourFormat, isLoading };
};

export default useHourFormat;
