/* eslint-disable max-len */
import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import GroupedBarChart from './charts/GroupedBarChart';
import LegendItem from './charts/LegendItem';
import PieChart from './charts/PieChart';
import { InventoryToolbar } from './FiltersToolbar';
import InventoryTabContext from './inventoryTabContext';
import { prepareInventoryDownload, useInventoryProducts } from './inventoryTabUtil';
import { INVENTORY_SETTINGS_KEYS } from './inventoryConstants';
import { usePriceRecDims } from './pricingTabUtil';
import { DashboardContext } from './revenueUpliftContexts';
import { parseDatasetDate } from './timePeriod';
import { ProfitRoverSecondaryButton } from '../../../forms/ProfitRoverButtons';
import TooltipHeader from '../../../grid/TooltipHeader';
import GridHeader from '../../../grid/GridHeader';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import { compactFormatNumber, formatNumber } from '../../../util/format';
import { useGridApi } from '../../../util/gridCommonFunctions';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import { CenteredProfitRoverSpinner } from '../../../spinner/ProfitRoverSpinner';
import { MEDIUM_LIGHT_GREEN, LIGHT_BLUE, MEDIUM_COOL_GRAY } from '../../../../colors';
import DownloadIcon from '../../../../images/download-icon.svg';
import { DATE_RANGE_TO_LABEL, formatDateRangeLabel } from '../../../../utils/date-handling';
import './inventory-tab.scss';

const TOOLTIP_DELAY = { show: 100, hide: 100 };
const INVENTORY_TABS = {
  A_PRODUCT: 'A_PRODUCT',
  B_PRODUCT: 'B_PRODUCT',
  C_PRODUCT: 'C_PRODUCT',
};

const convertToCSV = data => {
  const sanitizeCSVValue = value => {
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  };

  const headers = Object.keys(data[0]).join(',');
  const rows = data.map(obj =>
    Object.values(obj)
      .map(sanitizeCSVValue)
      .join(','),
  );
  return [headers, ...rows].join('\n');
};

const downloadDataAsCSV = (csv, fileName) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

const grossPotentialFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: true, currencySymbol, showCents: false });
    }

    return '-';
  };
};

const priceFormatter = currencySymbol => {
  return params => {
    const { value } = params;
    if (value) {
      return compactFormatNumber(params.value, { formatAsCurrency: true, currencySymbol, showCents: true });
    }

    return '-';
  };
};

const quantityFormatter = () => {
  return params => {
    const { value } = params;
    return formatNumber(value);
  };
};

const dateValueGetter = params => {
  const { inventoryOutDate } = params.data;

  if (!inventoryOutDate) {
    return null;
  }

  const date = parseDatasetDate(inventoryOutDate);

  return Number.isNaN(date.getTime()) ? null : date.getTime();
};

const dateFormatter = params => {
  const { value } = params;

  if (!value) {
    return '-';
  }

  return format(new Date(value), 'MMM d, yyyy');
};

const dateComparator = (valueA, valueB) => {
  if (valueA === null && valueB === null) {
    return 0;
  }

  if (valueA === null) {
    return 1;
  }
  if (valueB === null) {
    return -1;
  }

  return valueA - valueB;
};

const ProductTable = ({ products, productDimCols, isActive }) => {
  const { currencySymbol, selectedRange } = React.useContext(DashboardContext);
  const containerRef = React.useRef();
  const timePeriod = formatDateRangeLabel(selectedRange);

  const { gridApi, onGridReady } = useGridApi();
  const dimsById = usePriceRecDims();

  React.useEffect(() => {
    const gridResizeMonitor = new ResizeObserver(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    });

    if (containerRef.current) {
      gridResizeMonitor.observe(containerRef.current);
    }

    return () => gridResizeMonitor.disconnect();
  }, [gridApi, isActive]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isActive && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [gridApi, isActive, products]);

  const isLoading = gridApi == null;

  return (
    <div className="product-table-container mt-2" ref={containerRef}>
      {isLoading && (
        <div className="w-100 d-flex justify-content-center align-self-center">
          <CenteredProfitRoverSpinner />
        </div>
      )}
      <div className={classNames('ag-theme-alpine price-rec-table', { hidden: isLoading })}>
        <AgGridReact
          rowData={products}
          onGridReady={onGridReady}
          defaultColDef={{
            sortable: true,
            resizable: true,
            headerClass: 'border-right center-text',
          }}
          frameworkComponents={{
            tooltipHeader: TooltipHeader,
            gridHeader: GridHeader,
          }}
          groupHeaderHeight={30}
          headerHeight={45}
          maintainColumnOrder
          singleClickEdit
          suppressModelUpdateAfterUpdateTransaction
          stopEditingWhenCellsLoseFocus
        >
          {productDimCols.sort().map(dimId => (
            <AgGridColumn
              key={dimId}
              field={dimId}
              cellClass="border-right dimension-values"
              headerComponent="gridHeader"
              headerClass="border-right justify-content-center"
              headerComponentParams={{
                name: dimsById[dimId].name,
              }}
              minWidth={150}
            />
          ))}
          <AgGridColumn
            headerName="Price"
            cellClass="border-right center-text"
            field="price"
            valueFormatter={priceFormatter(currencySymbol)}
            minWidth={80}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="cost"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Cost Per Unit',
              tooltipText: `The average cost of raw materials per unit sold (if applicable)`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
            minWidth={95}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="grossProfitPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Gross Profit Potential',
              tooltipText: `The amount of gross profit we believe you could make over ${timePeriod} if you avoid stockouts`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
            minWidth={115}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="revenuePotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Revenue Potential',
              tooltipText: `The amount of revenue we believe you could make over ${timePeriod} if you avoid stockouts`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
            minWidth={100}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="salesQuantityPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Sales Qty Potential',
              tooltipText: `The quantity we believe you could sell over ${timePeriod} if you avoid stockouts`,
            }}
            valueFormatter={quantityFormatter()}
            minWidth={100}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="currentStock"
            headerComponent="gridHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              name: 'Current Stock',
            }}
            valueFormatter={quantityFormatter()}
            minWidth={90}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="orderQuantityRec"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'RoverRecs Order Qty',
              tooltipText: `This is the additional quantity we recommend you procure to avoid stockouts during ${timePeriod}`,
            }}
            valueFormatter={quantityFormatter()}
            minWidth={110}
          />
          <AgGridColumn
            cellClass="border-right center-text"
            field="missedGrossProfitPotential"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            headerComponentParams={{
              displayName: 'Gross Profit At Risk',
              tooltipText: `The amount of gross profit we believe you will miss out on during ${timePeriod} if you don’t increase your inventory`,
            }}
            valueFormatter={grossPotentialFormatter(currencySymbol)}
            minWidth={115}
          />
          <AgGridColumn
            cellClass="center-text"
            field="inventoryOutDate"
            headerComponent="tooltipHeader"
            headerClass="border-right justify-content-center"
            valueGetter={dateValueGetter}
            valueFormatter={dateFormatter}
            comparator={dateComparator}
            headerComponentParams={{
              displayName: 'Stockout Date',
              tooltipText: "The date we expect your current stock to run out if you don't reorder",
            }}
            minWidth={120}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

const filterProducts = (products, groupedFilters) => {
  if (Object.keys(groupedFilters).length === 0) {
    return products; // No filters, return all products
  }

  return products.filter(product =>
    Object.entries(groupedFilters).every(([id, labels]) => {
      const productLabel = product[id];
      return productLabel && labels.includes(productLabel);
    }),
  );
};

const InventoryProducts = ({ isActive }) => {
  const {
    currencySymbol,
    dimensionPreviews,
    filteredDimensionsSet,
    inventorySettingsApi,
    selectedRange,
  } = React.useContext(DashboardContext);
  const { productDimCols, segmentAProducts, segmentBProducts, segmentCProducts } = React.useContext(
    InventoryTabContext,
  );
  const [activeTab, setActiveTab] = React.useState(INVENTORY_TABS.A_PRODUCT);
  const [downloadDisabled, setDownloadDisabled] = React.useState(false);

  const { currentInventorySettings } = inventorySettingsApi;
  const topProductsPercentage = currentInventorySettings[INVENTORY_SETTINGS_KEYS.TOP_PRODUCTS_PERCENTAGE];
  const bottomProductsPercentage = currentInventorySettings[INVENTORY_SETTINGS_KEYS.BOTTOM_PRODUCTS_PERCENTAGE];
  const middleProductsPercentage = 100 - (topProductsPercentage + bottomProductsPercentage);

  const timePeriod = DATE_RANGE_TO_LABEL[selectedRange];

  const dimPreviewsToShow = dimensionPreviews.filter(
    dimPreview => filteredDimensionsSet.has(String(dimPreview.id)) && productDimCols.includes(String(dimPreview.id)),
  );

  const groupedFilters = React.useMemo(() => {
    return dimPreviewsToShow.reduce((acc, dimPreview) => {
      const selectedValues = dimPreview.dimValues.filter(dimValue => dimValue.isSelected);

      if (selectedValues.length > 0) {
        acc[dimPreview.id] = selectedValues.map(dimValue => dimValue.label);
      }

      return acc;
    }, {});
  }, [dimPreviewsToShow]);

  const filteredAProducts = React.useMemo(() => filterProducts(segmentAProducts, groupedFilters), [
    segmentAProducts,
    groupedFilters,
  ]);
  const filteredBProducts = React.useMemo(() => filterProducts(segmentBProducts, groupedFilters), [
    segmentBProducts,
    groupedFilters,
  ]);
  const filteredCProducts = React.useMemo(() => filterProducts(segmentCProducts, groupedFilters), [
    segmentCProducts,
    groupedFilters,
  ]);

  const colors = [MEDIUM_LIGHT_GREEN, LIGHT_BLUE, MEDIUM_COOL_GRAY];
  const barChartKeys = ['A', 'B', 'C'];

  const calculateTotals = dataKey => ({
    A: filteredAProducts.reduce((sum, item) => sum + item[dataKey], 0),
    B: filteredBProducts.reduce((sum, item) => sum + item[dataKey], 0),
    C: filteredCProducts.reduce((sum, item) => sum + item[dataKey], 0),
  });

  const pieChartData = React.useMemo(
    () => [
      { name: 'A', value: filteredAProducts.length },
      { name: 'B', value: filteredBProducts.length },
      { name: 'C', value: filteredCProducts.length },
    ],
    [filteredAProducts.length, filteredBProducts.length, filteredCProducts.length],
  );

  const grossProfitData = [
    { name: 'Potential', ...calculateTotals('grossProfitPotential') },
    { name: 'At Risk', ...calculateTotals('missedGrossProfitPotential') },
  ];

  const revenueData = [
    { name: 'Potential', ...calculateTotals('revenuePotential') },
    { name: 'At Risk', ...calculateTotals('missedRevenue') },
  ];

  const salesQuantityData = [
    { name: 'Potential', ...calculateTotals('salesQuantityPotential') },
    { name: 'At Risk', ...calculateTotals('missedSalesQuantity') },
  ];

  const renderBarChart = (title, data, valueFormatter = null) => (
    <div className="bar-chart-container">
      <h4>{title}</h4>
      <GroupedBarChart
        data={data}
        keys={barChartKeys}
        colors={colors}
        width={400}
        height={250}
        valueFormatter={valueFormatter}
      />
    </div>
  );

  const onDownloadClick = () => {
    setDownloadDisabled(true);

    let segment;
    let products;

    // Determine the segment and products based on the active tab
    switch (activeTab) {
      case INVENTORY_TABS.A_PRODUCT:
        segment = 'A';
        products = filteredAProducts;
        break;
      case INVENTORY_TABS.B_PRODUCT:
        segment = 'B';
        products = filteredBProducts;
        break;
      case INVENTORY_TABS.C_PRODUCT:
        segment = 'C';
        products = filteredCProducts;
        break;
      default:
        setDownloadDisabled(false);
        return;
    }

    const data = prepareInventoryDownload(segment, products, dimensionPreviews, timePeriod);
    const csv = convertToCSV(data);
    downloadDataAsCSV(csv, `${segment}-Products.csv`);

    // Add a delay to prevent multiple downloads
    setTimeout(() => {
      setDownloadDisabled(false);
    }, 1500);
  };

  return (
    <div className="inventory-products">
      <div className="charts-container">
        <div className="pie-chart-container">
          <h4>Product Count</h4>
          <PieChart
            data={pieChartData}
            colors={colors}
            width={200}
            height={200}
            valueFormatter={value => formatNumber(value)}
          />
        </div>
        {renderBarChart('Gross Profit', grossProfitData, value =>
          compactFormatNumber(value, {
            formatAsCurrency: true,
            fixedDecimalDigits: 0,
            currencySymbol,
          }),
        )}
        {renderBarChart('Revenue', revenueData, value =>
          compactFormatNumber(value, {
            formatAsCurrency: true,
            fixedDecimalDigits: 0,
            currencySymbol,
          }),
        )}
        {renderBarChart('Sales Qty', salesQuantityData, value => compactFormatNumber(value, { showCents: true }))}
      </div>

      <div className="legend-container">
        <LegendItem width={20} height={20} color={MEDIUM_LIGHT_GREEN} label="A" />
        <LegendItem width={20} height={20} color={LIGHT_BLUE} label="B" />
        <LegendItem width={20} height={20} color={MEDIUM_COOL_GRAY} label="C" />
      </div>

      <Tab.Container activeKey={activeTab} onSelect={setActiveTab}>
        <Tab.Content>
          <Nav className="nav-container">
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.A_PRODUCT ? 'active-a' : 'inactive-a'}
                eventKey={INVENTORY_TABS.A_PRODUCT}
              >
                &quot;A&quot; Products
                <ProfitRoverTooltip
                  tooltipText={`These products account for the top ${topProductsPercentage}% of your gross profit potential for ${formatDateRangeLabel(
                    selectedRange,
                  )}`}
                  placement="top"
                  delay={TOOLTIP_DELAY}
                >
                  <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
                </ProfitRoverTooltip>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.B_PRODUCT ? 'active-b' : 'inactive-b'}
                eventKey={INVENTORY_TABS.B_PRODUCT}
              >
                &quot;B&quot; Products
                <ProfitRoverTooltip
                  tooltipText={`These products account for the next ${middleProductsPercentage}% of your gross profit potential for ${formatDateRangeLabel(
                    selectedRange,
                  )}`}
                  placement="top"
                  delay={TOOLTIP_DELAY}
                >
                  <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
                </ProfitRoverTooltip>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={activeTab === INVENTORY_TABS.C_PRODUCT ? 'active-c' : 'inactive-c'}
                eventKey={INVENTORY_TABS.C_PRODUCT}
              >
                &quot;C&quot; Products
                <ProfitRoverTooltip
                  tooltipText={`These products account for the bottom ${bottomProductsPercentage}% of your gross profit potential for ${formatDateRangeLabel(
                    selectedRange,
                  )}`}
                  placement="top"
                  delay={TOOLTIP_DELAY}
                >
                  <FontAwesomeIcon className="ml-1" icon={faCircleQuestion} />
                </ProfitRoverTooltip>
              </Nav.Link>
            </Nav.Item>
            <ProfitRoverSecondaryButton onClick={onDownloadClick} disabled={downloadDisabled}>
              <img className="download-icon" src={DownloadIcon} alt="" />
              Download
            </ProfitRoverSecondaryButton>
          </Nav>
          <Tab.Pane eventKey={INVENTORY_TABS.A_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={filteredAProducts} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={INVENTORY_TABS.B_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={filteredBProducts} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={INVENTORY_TABS.C_PRODUCT} unmountOnExit>
            <div className="tab-content">
              <ProductTable products={filteredCProducts} isActive={isActive} productDimCols={productDimCols} />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const InventoryTab = ({ isActive }) => {
  const { selectedLocation, setSelectedLocation, locationOptions } = React.useContext(InventoryTabContext);

  return (
    <div className="inventory-tab">
      <div className="my-2">
        <InventoryToolbar
          locationOptions={locationOptions}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      </div>
      <ProfitRoverCard className="inventory-card">
        <div className="inventory-card-content">
          <InventoryProducts isActive={isActive} />
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const InventoryTabContextController = ({ children }) => {
  const { locationsApi } = React.useContext(DashboardContext);
  const { selectedLocation, setSelectedLocation, locationOptions } = locationsApi;
  const {
    inventoryProducts,
    productDimCols,
    segmentAProducts,
    segmentBProducts,
    segmentCProducts,
  } = useInventoryProducts(selectedLocation?.value);

  const contextValue = {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    inventoryProducts,
    productDimCols,
    segmentAProducts,
    segmentBProducts,
    segmentCProducts,
  };

  return <InventoryTabContext.Provider value={contextValue}>{children}</InventoryTabContext.Provider>;
};

const InventoryTabContainer = ({ isActive }) => {
  const { isLoadingInventory, isLoadingPricingScenarios } = React.useContext(DashboardContext);
  // const { isLoadingHoursSettings } = hoursOfOperationSettingsApi;

  if (isLoadingInventory || isLoadingPricingScenarios) {
    return (
      <div className="hours-tab h-100">
        <CenteredProfitRoverSpinner />
      </div>
    );
  }

  return (
    <InventoryTabContextController>
      <InventoryTab isActive={isActive} />
    </InventoryTabContextController>
  );
};

export default InventoryTabContainer;
